<template>
	<v-layout justify-center align-center style="background-color: #007BED;height: 100%;">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0">{{message}}</p>
		</v-snackbar>
		<v-container>
			<v-row>
				<v-col cols="12">
					<h3 class="text-center white--text">Ingresa tu nueva contraseña</h3>
					<v-layout flex-column flex-md-row justify-center align-center style="width: 100%">
						<v-img
							max-height="300"
							max-width="300"
							:src="require('../../assets/logo-iduam.svg')"
						></v-img>
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
							class="d-flex flex-column flex-grow-1"
							style="max-width: 400px;width: 100%;"
						>
							<v-text-field
								v-model="password"
								:rules="passwordRules"
								label="Contraseña"
								dark
								required
								:append-icon="passwordType ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="() => (passwordType = !passwordType)"
								:type="passwordType ? 'password' : 'text'"
							></v-text-field>
							<v-text-field
								v-model="password_confirmation"
								:rules="passwordRules"
								label="Confirmar Contraseña"
								dark
								required
								type="password"
							></v-text-field>
							<v-btn
								:disabled="!valid"
								color="success"
								class="mr-4"
								@click="changePass()"
							>
								Confirmar
							</v-btn>
						</v-form>
					</v-layout>
				</v-col>
			</v-row>
		</v-container>
	</v-layout>
</template>

<script>
	import {checkToken, resetPass} from '../../helpers/api/user'

	export default {
		name: 'ResetPass',
		data() {
			return {
				valid: false,
				showMessage: false,
				messageColor: 'error',
				message: '',
				token: '',
				passwordRules: [
					v => !!v || 'Contraseña es requerida'
				],
				email: '',
				password: '',
				password_confirmation: '',
				passwordType: 'password',
				capsTooltip: false,
				loading: false,
				showDialog: false,
				redirect: undefined,
				otherQuery: {}
			}
		},
		created() {
			this.token = this.$route.params.token.toString()
			this.checkUserToken(this.token)
		},
		mounted() {
		},
		methods: {
			checkUserToken(token) {
				const self = this
				checkToken(token)
					.then(function(response) {
						self.email = response.data.email
					})
					.catch(function(error) {
						console.log('E: ', error)
					})
			},
			changePass() {
				this.$refs.form.validate()
				if (this.password != '' && this.password === this.password_confirmation) {
					const self = this
					const data = {
						email: this.email,
						password: this.password,
						password_confirmation: this.password_confirmation,
						token: this.token
					}
					resetPass(data)
						.then(function() {
							localStorage.removeItem('token')
							self.message = 'La contraseña ha sido reestablecida, por favor ingrese con su nueva contraseña.'
							self.messageColor = 'success'
							self.showMessage = true
							self.$router.push({ name: 'Login' })
						})
						.catch(function(error) {
							console.log('E: ', error)
							self.message = 'Ha ocurrido un error al intentar reestablecer la contraseña, por favor inténtalo nuevamente.'
							self.messageColor = 'error'
							self.showMessage = true
						})
				} else {
					this.message = 'Las contraseñas no coinciden, por favor verifica los datos ingresados.'
					this.messageColor = 'warning'
					this.showMessage = true
					return false
				}
			}
		}
	}
</script>

<style lang="scss">
	/* 修复input 背景不协调 和光标变色 */
	/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

	$bg:#283443;
	$light_gray:#fff;
	$cursor: #fff;

	@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
		.login-container .el-input input {
			color: $cursor;
		}
	}

	/* reset element-ui css */
	.login-container {
		.el-input {
			display: inline-block;
			height: 47px;
			width: 85%;

			input {
				background: transparent;
				border: 0px;
				-webkit-appearance: none;
				border-radius: 0px;
				padding: 12px 5px 12px 15px;
				color: $light_gray;
				height: 47px;
				caret-color: $cursor;

				&:-webkit-autofill {
					box-shadow: 0 0 0px 1000px $bg inset !important;
					-webkit-text-fill-color: $cursor !important;
				}
			}
		}

		.el-form-item {
			border: 1px solid rgba(255, 255, 255, 0.1);
			background: rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			color: #454545;
		}
	}
</style>

<style lang="scss" scoped>
	$bg:#2d3a4b;
	$dark_gray:#889aa4;
	$light_gray:#eee;

	.login-container {
		min-height: 100%;
		width: 100%;
		background-color: $bg;
		overflow: hidden;

		.login-form {
			position: relative;
			width: 520px;
			max-width: 100%;
			padding: 160px 35px 0;
			margin: 0 auto;
			overflow: hidden;
		}

		.tips {
			font-size: 14px;
			color: #fff;
			margin-bottom: 10px;

			span {
				&:first-of-type {
					margin-right: 16px;
				}
			}
		}

		.svg-container {
			padding: 6px 5px 6px 15px;
			color: $dark_gray;
			vertical-align: middle;
			width: 30px;
			display: inline-block;
		}

		.title-container {
			position: relative;

			.title {
				font-size: 26px;
				color: $light_gray;
				margin: 0px auto 40px auto;
				text-align: center;
				font-weight: bold;
			}
		}

		.show-pwd {
			position: absolute;
			right: 10px;
			top: 7px;
			font-size: 16px;
			color: $dark_gray;
			cursor: pointer;
			user-select: none;
		}

		.thirdparty-button {
			position: absolute;
			right: 0;
			bottom: 6px;
		}

		@media only screen and (max-width: 470px) {
			.thirdparty-button {
				display: none;
			}
		}
	}
</style>
