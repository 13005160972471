const endpoints = {
	login: '/auth/login',
	loginWithGoogleToken: '/social-auth/loginWithGoogleToken',
	checkToken: '/auth/find',
	resetPass: '/auth/resetPass',
	getUserByCode: '/auth/getUserByCode',
	getUserWithSponsorCode: '/sponsor/getSponsorById',
	signUpByCode: '/auth/signUpByCode',
	user: '/app/user/details',
	updateUserAvatar: '/app/user/updateAvatar',
  updateUserInfo: '/app/user/updateInfo',
	createFirebaseAuthToken: 'app/user/createFirebaseAuthToken',
	userDetails: 'admin/user/details',
	lostMyPhoneData: 'app/lostphone/getLostMyPhoneData',
	startLostMyPhone: 'app/lostphone/lostMyPhoneSubscribeNotification',
	stopLostMyPhone: 'app/lostphone/lostMyPhoneUnSubscribeNotification',
	lockMyPhone: 'app/lostphone/blockMyPhoneNotification',
	wipePhoneData: 'app/lostphone/wipePhoneDataNotification',
	changeLostMyPhoneStatus: 'app/lostphone/changeStatus',
	getLostMyPhoneInfo: 'app/lostphone/getLostMyPhoneInfo',
	userMediciones: '/app/mediciones/getUserMediciones',
	startRemoteTest: '/app/mediciones/startRemoteTest',
	autoMedicion: '/app/automedicion/updateOrCreate',
	deactivateAutoMedicion: '/app/automedicion/deactivate',
	validatePass: '/app/user/validatePass',
	getComunasActivas: '/auth/getComunasActivas',
	getAlertTempUserData: '/tempalert/getAlertTempUserData',
	getAtenciones: 'app/atenciones/getUserAtenciones',
	getAtencionDetail: 'app/atenciones/getAtencionDetail',
	getPlanes: '/app/planes/getPlanes',
	getPaymentValue: '/app/planes/getToPayData',
	startPaymentPlan: '/app/pagos/newPlanPayment',
	getOrderStatusByToken: '/auth/getOrderStatusByToken',
	getSecurityNet: '/app/grupos/misInvitados',
	getSecurityExternal: '/app/grupos/misGrupos',
	getTrackingByDevice: '/app/tracking/getTrackingByDevice',
	activateTracking: 'admin/tracking/toogleTrackingNotification',
	sendInvitation: '/app/grupos/enviarInvitacion',  
	comunasActivas: '/auth/getComunasActivas',
	regionesActivas: '/auth/getRegionesActivas',
	paisesActivos: '/auth/getPaisesActivos',
	changeSalesStatus: '/app/sales/changeStatus',
	getSalesStatus: '/app/sales/getStatus',
	getUserPaymentStatus: '/app/sales/getUserPaymentStatus',
	getSalesResumen: '/app/sales/getSalesResumen',
	getSalesData: '/app/sales/getSalesData',
	updateBankAccount: '/app/sales/updateBankAccount',
	deleteAccount: 'app/user/delete',
	preRegister: '/auth/preregisterUserToCompany',
	getPreregisterUser: '/auth/getPreregisterUser'
}
export {endpoints}
