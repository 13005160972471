<template>
	<v-container fill-height align-start style="max-height: 100%;" ref="containerDeviceInfo">
		<v-row ref="headerInfo">
			<v-col cols="12" sm="6">
				<h4 class="d-flex align-center">
					<v-img
						:src="require('../../assets/imgs/device-info.svg')"
						width="70"
						height="70"
						max-width="70"
						max-height="70"
					></v-img>
					<span class="headline textcolor-sosclick" style="color: #707070;">Información Recopilada</span>
					<v-btn
						color="success"
						rounded
						outlined
						small
						:disabled="$store.state.selectedDevice === null"
						@click="showDeviceInfo($store.state.selectedDevice)"
						class="mx-2"
						style="background-color: white"
					>
						<v-icon>
							mdi-reload
						</v-icon>
						<p class="body-2 mb-0" >Actualizar</p>
					</v-btn>
				</h4>
			</v-col>
			<v-col cols="12" sm="6">
				<v-alert
					color="warning"
					dark
					dense
					icon="mdi-information"
					border="left"
					prominent
					class="custom-alert-info mb-0"
				>
					<h5>Información del dispositivo</h5>
					<p class="caption mb-0">Se muestra el listado de imágenes recopiladas y la ubicación de estas en el mapa.</p>
					<p class="caption mb-0"><b>Por favor ten en cuenta:</b> Si activaste esta opción hace pocos minutos tu dispositivo puede estar en proceso de envío de datos, por lo que es posible que la información no aparezca aún en este listado.</p>
				</v-alert>
			</v-col>
			<v-col cols="12">
				<v-alert
					v-if="$store.state.selectedDevice && $store.state.selectedDevice.device_so === 'android' && $store.state.selectedDevice.device_soversion > 10"
					color="#007BED"
					dark
					dense
					dismissible
					icon="mdi-alert"
					border="left"
					prominent
					class="custom-alert-info ma-0 mt-2"
				>
					<p class="body-2 mb-0 font-weight-bold">Información Importante Android 11</p>
					<p class="caption mb-0">Debido a las restricciones implementadas en la nueva versión Android 11 (lanzada en septiembre de 2020) no podemos tomar fotografías de forma incognita (con la aplicación cerrada). Mientras buscamos una solución a esto sólo podremos entregarte la ubicación del dispositivo. Lamentamos los inconvenientes que esto pueda ocasionarte.</p>
					<p class="caption mb-0 font-weight-bold">Por favor ten en cuenta que esta restricción es sólo para dispositivos con Android 11 o superior, en versiones previas esta función trabaja de forma normal.</p>
					<p class="caption px-4 mt-2 mb-0 font-weight-bold white--text text-center"><a class="white--text" href="https://andro4all.com/listas/moviles/moviles-actualizacion-android-11" target="_blank">Conoce aquí los dispositivos y la fecha en la que podrán recibir la actualización a Android 11</a></p>
				</v-alert>
			</v-col>
		</v-row>

		<v-row class="text-center fill-height" v-if="deviceInfo.length > 0" :style="{'max-height': height + 'px'}">
			<v-col cols="12" sm="6" style="max-height: 100%;">
				<GmapMap v-if="deviceInfo.length > 0" ref="map" :center="centerMap" :zoom="mapZoom"
					:options="{ maxZoom: 16, streetViewControl: true, mapTypeControl: true, zoomControl: true }"
					class="map-container" style="min-height: 300px">
					<GmapMarker
						:key="'b'+index"
						:ref="'b'+index"
						v-for="(m, index) in deviceInfo"
						:position="m.position"
						:visible="highlightMarker === -1 || highlightMarker === index"
						:icon="{
							url: highlightMarker === index ? require('../../assets/imgs/marker4.png'):require('../../assets/imgs/marker2.png'),
							scaledSize: { width: 32, height: 40 }}"
					/>
				</GmapMap>
			</v-col>
			<v-col cols="12" sm="6" style="max-height: 100%;overflow-y: scroll;">
				<div>
					<div class="d-flex flex-wrap mb-2">
						<v-card
							v-for="(item, index) in deviceInfo"
							:key="index"
							class="mx-auto my-2"
							max-width="150"
						>
							<v-img
								v-if="item.image"
								height="150"
								:src="item.image_url"
							>
								<template v-slot:placeholder>
									<v-row
										class="fill-height ma-0"
										align="center"
										justify="center"
									>
										<v-progress-circular
											indeterminate
											color="#007BED"
										></v-progress-circular>
									</v-row>
								</template>
								<div class="d-flex justify-start my-1 mx-2">
									<LMPModalImage :lmpInfo="item"/>
								</div>
							</v-img>
							<div v-else class="d-flex flex-column align-center justify-center" style="width: 100%;height: 150px; background-color: #d2d2d2;">
								<v-icon
									large
									v-bind="attrs"
									v-on="on">
									mdi-image-off-outline
								</v-icon>
								<p class="caption mb-0">Sin imagen disponible</p>

							</div>

							<v-card-text class="caption pa-1 d-flex align-center justify-space-around">
								{{item.created_at}}
								<v-tooltip right>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											class="mx-2"
											fab
											dark
											x-small
											color="#007BED"
											@click="showMarker(index)"
										>
											<v-icon
												v-bind="attrs"
												v-on="on">
												mdi-map-marker
											</v-icon>
										</v-btn>
									</template>
									<span>Marcar en mapa</span>
								</v-tooltip>
							</v-card-text>
							<v-card-actions>
							</v-card-actions>
						</v-card>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row class="text-center" v-else>
			<v-col cols="12">	
				<div class="mt-5">
					<v-icon x-large color="#007BED">
						mdi-alert-circle-outline
					</v-icon>
					<p class="body-2">No hay información para este dispositivo.</p>
					<p class="caption" style="max-width: 400px;margin:auto;">Si activaste la función de perdida en algún momento recuerda que el sistema solicita las imágenes al dispositivo cada 1 hora.</p>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { mapGetters } from "vuex"
	import {gmapApi} from 'vue2-google-maps'
	import LMPModalImage from './ImageModal'
	import {getLostMyPhoneInfo} from '../../helpers/api/user'

	export default {
		name: 'DeviceLostInfo',
		computed: {
			...mapGetters(["selectedDeviceData"]),
			google: gmapApi
		},
		components: {LMPModalImage},
		data: () => ({
			centerMap: {
				lat: -33.4373063,
				lng: -70.634297
			},
			mapZoom: 12,
			highlightMarker: -1,
			deviceInfo: [],
			height: 200
		}),
		watch:{
			selectedDeviceData: function(data){
				this.showDeviceInfo(data)
			}
		},
		mounted () {
			if(this.$store.state.selectedDevice){
				this.showDeviceInfo(this.$store.state.selectedDevice)
			}
			this.height = this.$refs.containerDeviceInfo.clientHeight - this.$refs.headerInfo.clientHeight - 12
		},
		methods: {
			async showDeviceInfo(device){
				const info = await getLostMyPhoneInfo({device_id: device.device_id})
				if(info.status === 200){
					this.deviceInfo = info.info
					if(this.deviceInfo.length > 0){
						setTimeout(() => {
							this.highlightMarker = -1
							this.fitMarkers(this.deviceInfo)
						}, 500);
					}			
				}
			},
			fitMarkers(markers){
				let bounds = new this.google.maps.LatLngBounds();
				markers.map((item) => {
					bounds.extend(new this.google.maps.LatLng(item.position.lat, item.position.lng))
				})
				this.$refs.map.fitBounds(bounds, 0)
			},
			showMarker(index){
				this.centerMap = this.deviceInfo[index].position
				this.highlightMarker = index
			}
		}
	}
</script>
