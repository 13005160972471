<template>
  <v-container
    fluid
    fill-height
    class="grey lighten-4 py-0"
    style="max-height: 100%; overflow-y: scroll"
  >
    <v-row ref="headerInfo">
      <v-col cols="12" sm="12">
        <h4 class="d-flex align-center">
          <v-img
            :src="require('../../assets/imgs/take-measurements.svg')"
            max-width="70"
            max-height="70"
          ></v-img>
          <span class="headline textcolor-sosclick" style="color: #707070"
            >Mediciones</span
          >
        </h4>
      </v-col>
      <v-col cols="12" sm="12" v-if="remoteTest.endTest">
        <v-card>
          <!-- card que muestre name de smartband, time range y dispositivo -->
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <h3 class="font-size-36 text-center">
                  Elige Dispositivo y Smartband para realizar una medición
                </h3>
              </v-col>
              <v-col cols="6" sm="3" md="3">
                <v-select
                  v-model="dispositivo"
                  :items="[...dispositivos]"
                  item-text="device_name"
                  item-value="id"
                  messages="Seleccione un Dispositivo"
                  label="Dispositivos"
                  class="mr-2"
                  no-data-text="No hay dispositivos"
                  :disabled="loading"
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" md="3">
                <v-select
                  v-model="smartband"
                  :items="[...smartbands]"
                  item-text="name"
                  item-value="id"
                  messages="Seleccione un Smartband"
                  label="Smartband"
                  class="mr-2"
                  no-data-text="No hay Smartbands"
                  :disabled="loading"
                ></v-select>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                md="3"
                class="d-flex align-center justify-center"
              >
                <v-btn
                  color="primary"
                  class="mr-2"
									small
                  @click="realizarMedicion"
                  :disabled="loading || !dispositivo || !smartband"
                >
                  <v-icon>mdi-heart-pulse</v-icon>
                  <span>Realizar Medición</span>
                </v-btn>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                md="3"
                class="d-flex align-center justify-center"
              >
                <v-btn
                  color="primary"
                  class="mr-2"
									small
                  @click="toggleReportModal"
                  v-if="$store.state.reportData"
                >
                  <v-icon>mdi-heart-pulse</v-icon>
                  <span>Visualizar Reporte</span>
                </v-btn>
								<div v-else-if="remoteTest.loadingReport" class="d-flex align-center justify-center">
									<v-progress-circular
										indeterminate
										color="primary"
										size="20"
										class="mr-2"
									></v-progress-circular>
									Cargando Reporte
								</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        v-if="remoteTest.needTouchElectrode && !remoteTest.endTest"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <h3 class="font-size-36 text-center">
                  Por favor toque el sensor de su Smartband
                  <v-icon style="font-size: 50px" class="pulse"
                    >mdi-heart-pulse</v-icon
                  >
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        v-if="remoteTest.inProgress && !remoteTest.endTest"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <h3 class="font-size-36 text-center">Medición en progreso</h3>
              </v-col>
              <v-col cols="12" sm="12">
                <v-progress-linear
                  :indeterminate="loading"
                  :value="remoteTest.progress"
                  color="primary"
                  class="mb-0"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="(value, name, index) in mediciones"
        :key="name"
      >
        <Medicion :index="index" :medida="name" :value="value" />
      </v-col>
      <!-- </div> -->
      <v-col
        cols="12"
        v-if="chartData && chartData.labels.length > 0"
        :key="ecgkey"
      >
        <v-card class="rounded-lg">
          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-avatar>
                <v-img :src="require('../../assets/imgs/electro.svg')"></v-img>
              </v-list-item-avatar>
              Electro
            </v-list-item>
          </v-card-actions>
          <v-card-text>
            <ElectroGraph :chartData="chartData" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { startRemoteTest } from "../../helpers/api/mediciones";
import moment from "moment";
import Medicion from "./Medicion";
import ElectroGraph from "./ElectroGraph";
import * as firebase from "firebase/app";
import "firebase/database";

export default {
  name: "Mediciones",
  components: {
    Medicion,
    ElectroGraph
  },
  filters: {
    date: function (value) {
      if (!value) return "No registra";
      return moment(value).format("DD-MM-YYYY HH:mm");
    },
  },
  data() {
    return {
      loading: false,
      smartbands: [],
      smartband: "",
      dispositivos: [],
      dispositivo: "",
      idTest: "",
      ecgkey: 0,
      mediciones: {
        // Temperatura: ["37°"],

        "Saturación de oxígeno": [
          this.$store.state.userData.mediciones[0].oxigeno || "--",
          "%",
        ],

        "Presión Arterial": [
          `${this.$store.state.userData.mediciones[0].sistolica || "--"}/${
            this.$store.state.userData.mediciones[0].diastolica || "--"
          }`,
          "mmHg",
        ],

        "Ritmo Cardíaco": [
          this.$store.state.userData.mediciones[0].ritmo || "--",
          "min",
        ],
      },
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            fill: false,
            borderColor: "red",
          },
        ],
      },
      remoteTest: {
        progress: 100,
        counter: 0,
        endTest: true,
        extendedTime: false,
        inProgress: false,
        needTouchElectrode: false,
        liteEcgData: [],
      },
    };
  },
  mounted() {
    this.getSmartbands()
    this.getDispositivos()
  },
	beforeUnmount(){
		firebase.database(this.$store.state.primaryDB).ref("IduamTests/remote/" + this.idTest).on("value")
		this.$store.commit('setReportData', null)
	},
  methods: {
		toggleReportModal(){
			this.$store.commit('setModalsStates', {testReport: !this.$store.state.modalsStates.testReport})
		},
    realizarMedicion() {
			this.remoteTest = {
        progress: 100,
        counter: 0,
        endTest: true,
        extendedTime: false,
        inProgress: false,
        needTouchElectrode: false,
        liteEcgData: [],
      }
      this.loading = true;
      startRemoteTest({
        smartband_id: this.smartband,
        device_id: this.dispositivo,
      })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.idTest = response.testIdentifier;
          this.remoteTest.endTest = false;
          this.createListenners();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getSmartbands() {
      this.smartbands = [...this.$store.state.userData.smartbands];
    },
    getDispositivos() {
      this.dispositivos = [...this.$store.state.userData.dispositivos];
    },
    createListenners() {
      firebase
        .database(this.$store.state.primaryDB)
        .ref("IduamTests/remote/" + this.idTest)
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            this.remoteTest = {
              ...this.remoteTest,
              ...snapshot.val(),
            };
            this.getMediciones();
          }
          if (
						(this.remoteTest && this.remoteTest.endTest && !this.remoteTest.loadingReport) ||
						(this.remoteTest && !!this.remoteTest.report)
					) {
            // firebase
            //   .database(this.$store.state.primaryDB)
            //   .ref("IduamTests/remote/" + this.idTest)
            //   .remove();
            this.ecgkey = this.ecgkey + 1;
						if(this.remoteTest.report){
							// Levanta modal info reporte
							this.$store.commit('setReportData', this.remoteTest.report)
						}
          }
        }),
        (error) => {
          console.log(error);
          this.remoteTest.endTest = true;
        };
    },
    getMediciones() {
      this.mediciones = {
        "Saturación de oxígeno": [
          (this.remoteTest && this.remoteTest.bloodOxygen) || "--",
          "%",
        ],
        "Presión Arterial": [
          `${
            (this.remoteTest &&
              this.remoteTest.bloodPressure &&
              this.remoteTest.bloodPressure.sistolic) ||
            "--"
          }/${
            (this.remoteTest &&
              this.remoteTest.bloodPressure &&
              this.remoteTest.bloodPressure.diastolic) ||
            "--"
          }`,
          "mmHg",
        ],
        "Ritmo Cardíaco": [
          (this.remoteTest && this.remoteTest.heartRate) || "--",
          "min",
        ],
      };
      this.chartData = {
        labels: (this.remoteTest && this.remoteTest.liteEcgData) || [],
        datasets: [
          {
            data: (this.remoteTest && this.remoteTest.liteEcgData) || [],
            fill: false,
            borderColor: "red",
          },
        ],
      };
      this.ecgkey = this.ecgkey + 1;
    },
  },
};
</script>

<style scoped>
.indicador {
  background: linear-gradient(180deg, white 50%, #f3f3f3 50%);
}

.pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    color: #007bed;
  }
  100% {
    transform: scale(1);
  }
}

.icontainer {
  background: transparent;
}
</style>
