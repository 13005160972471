import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const token = localStorage.getItem('userToken')

const userStore = new Vuex.Store({
	state: {
		isAuthenticated: token,
		userData: null,
		lostPhoneInfo: null,
		deviceLockModal: null,
		primaryDB: null,
    trackingDB: null,
		monitoreoDB: null,
		selectedDevice: null,
		modalsStates: {
			notAllowed: false,
			lockDevice: false,
			lostPhone: false,
			wipeData: false,
			securityNet: false,
			editProfile: false,
			paymentStatus: {open: false, data: null},
			testReport: false
		},
		salesData: {
			referred_comision: 0,
			button_comision: 0
		},
		drawer: false,
		reportData: null,
		showNavbar: true
	},
	mutations: {
		setShowNavbar (state, showNavbar) {
			state.showNavbar = showNavbar
		},
		setUserData (state, user) {
			state.userData = user
			state.isAuthenticated = localStorage.getItem('userToken')
		},
		logoutUser (state) {
			state.userData = null
			state.isAuthenticated = null
			localStorage.removeItem('userToken')
		},
		setLostPhoneInfo (state, info) {
			state.lostPhoneInfo = info
		},
		setDeviceLockModal (state, info) {
			state.deviceLockModal = info
		},
		setPrimaryDB (state,value) {
			state.primaryDB = value
		},
    setTrackingDB (state,value) {
      state.trackingDB = value
    },
		setMonitoreoDB (state,value) {
			state.monitoreoDB = value
		},
		setSelectedDevice (state,value) {
			state.selectedDevice = value
		},
		setDrawerState (state,value) {
			state.drawer = value
		},
		setModalsStates (state,value) {
			const modalsStates = Object.assign({}, state.modalsStates, value)
			state.modalsStates = modalsStates
		},
		setSalesData (state, salesData) {
			state.salesData = salesData
		},
		setReportData (state, reportData) {
			state.reportData = reportData
			const modalsStates = Object.assign({}, state.modalsStates, {testReport: !!reportData})
			state.modalsStates = modalsStates
		}
	},
	getters: {
		userData: state => {
			return state.userData
		},
		lostPhoneInfoData: state => {
			return state.lostPhoneInfo
		},
		selectedDeviceData: state => {
			return state.selectedDevice
		},
		salesData: state => {
			return state.salesData
		}
	}
})

export default userStore
