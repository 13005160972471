import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import './assets/scss/index.scss'
import store from './stores/user'
import * as firebase from 'firebase/app'
import 'firebase/database'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from "vue2-google-maps/dist/components/cluster"

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyBSglZK8-pgReHnPzQfTcbdz7CyRQCly44",
  authDomain: "iduam-eebce.firebaseapp.com",
  databaseURL: "https://iduam-eebce.firebaseio.com",
  projectId: "iduam-eebce",
  storageBucket: "iduam-eebce.appspot.com",
  messagingSenderId: "574012982731",
  appId: "1:574012982731:web:230b225497bd9b2e8c624e",
  measurementId: "G-JT1JLJW9SP",
};
firebase.initializeApp(firebaseConfig);

Vue.component('GmapCluster', GmapCluster);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAVx5gJCh9MATmWOFIXOONY-YCc9R3yPlg',
  },
  installComponents: true
})

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
