import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login/Login'
import Register from '@/components/Login/Register'
import PreRegistro from '@/components/PreRegistro/PreRegistro'
import ResetPass from '@/components/Login/ResetPass'
import Home from '@/components/Home/Home'
import Atenciones from '@/components/Atenciones/Atenciones'
import DeviceLostInfo from '@/components/LostMyPhone/DeviceLostInfo'
import Planes from '@/components/Planes/Planes'
import Tracking from '@/components/Tracking/Tracking'
import Sales from '@/components/Sales/Index'
import Mediciones from "@/components/Mediciones/Mediciones"
import HistoricoMediciones from '@/components/Mediciones/HistoricoMediciones'
import AutoConfig from '@/components/Mediciones/AutoConfig'
import DeleteAccount from '@/components/DeleteAccount/delete-account'

import userStore from '../stores/user.js'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/register',
			component: Register,
			hidden: true,
			name: 'Register'
		},
		{
			path: '/pre-registro-arica',
			component: PreRegistro,
			hidden: true,
			name: 'PreRegistro'
		},
		{
			path: '/resetpass/:token',
			component: ResetPass,
			hidden: true,
			name: 'Reestablecer',
			meta: {
				title: 'Reestablecer Contraseña'
			}
		},
		{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: '/atenciones',
			name: 'Atenciones',
			component: Atenciones
		},
		{
			path: '/device-info',
			name: 'Information',
			component: DeviceLostInfo
		},
		{
			path: '/planes',
			name: 'Planes',
			component: Planes
		},
		{
			path: '/tracking',
			name: 'Tracking',
			component: Tracking
		},
		{
			path: '/sales',
			name: 'Sales',
			component: Sales
		},
		{
			path: '/mediciones',
			name: 'Mediciones',
			component: Mediciones
		},
		{
			path: '/autoconfig',
			name: 'AutoConfig',
			component: AutoConfig
		},
		{
			path: '/mediciones-historico',
			name: 'HistoricoMediciones',
			component: HistoricoMediciones
		},
		{
			path: '/delete-account',
			name: 'DeleteAccount',
			component: DeleteAccount
		},
	]
})

router.beforeEach((to, from, next) => {
	if (to.name !== 'Login' && to.name !== 'Register' && to.name !== 'PreRegistro' && to.name !== 'Reestablecer' && to.name !== 'Alert' && !userStore.state.isAuthenticated) next({ name: 'Login',query: { redirect_to: to.path } })
	else if ((to.name === 'Login') && userStore.state.isAuthenticated) next({ name: 'Home' })
	else next()
})

export default router
