import axios from 'axios'

// const BASE = 'https://zonecam.naturalphone.cl/apiSOSclick/public/api/' //Desarrollo
// const BASE = 'https://api.iduam.com/api/' //Producción
const BASE = 'https://devapis.anubys.cl:8004/api/' //Desarrollo

const service = axios.create({ baseURL: BASE })

service.defaults.timeout = 10000

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const token = localStorage.getItem('userToken')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    config.headers['Content-Type'] = 'application/json'
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
  },
  error => {
    // do something with request error
    console.log('API: ', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response
    console.log(res)
    // console.log(res.code)
    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 200) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.status === 403 || res.status === 50008 || res.status === 50012 || res.status === 50014) {
        // to re-login
        localStorage.removeItem('token')
        location.reload()
      }
      return Promise.reject(res || 'Error')
    } else {
      return res.data
    }
  },
  async error => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      await localStorage.removeItem('token')
      if(error.response.config && error.response.config.url != '/auth/login'){
        location.reload()
      }
    }
    console.log("error", error.response)
    return Promise.reject(error)
  }
)

export default service
