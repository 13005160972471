<template>
  <v-container
    fill-height
    align-start
    style="max-height: 100%; overflow-y: scroll"
    ref="containerInfo"
  >
    <v-row ref="headerInfo">
      <v-col cols="12" sm="12">
        <h4 class="d-flex align-center">
          <v-img
            :src="require('../../assets/imgs/historic-measurements.svg')"
            max-width="70"
            max-height="70"
          ></v-img>
          <span class="headline textcolor-sosclick"
            >Histórico de Mediciones</span
          >
        </h4>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Rango de fechas"
              messages="Seleccione un rango de fechas"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>          
            
          </template>
         
          <v-date-picker
            v-model="dates"
            no-title
            scrollable
            range
            :first-day-of-week="1"
            locale="es-cl"
            color="blue"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(dates)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      
      </v-col>

      <v-col cols="6" sm="3" md="3" class="d-flex align-center justify-center">
        <v-btn
          color="primary"
          class="mr-2"
          @click="getHistoricData"
          :disabled="dates.length < 2 || loading"
        >
          <v-icon>mdi-magnify</v-icon>
          <span>Buscar</span>
        </v-btn>
      </v-col>
      <v-col cols="6" sm="3" md="3">
        <v-select
          v-model="smartband"
          :items="['Todos', ...smartbands]"
          item-text="name"
          item-value="name"
          messages="Seleccione un Smartband"
          label="Smartband"
          class="mr-2"
          @change="filterBySmartband"
          :disabled="loading"
        ></v-select>
      </v-col>

      <v-col cols="12" md="4" sm="6" v-if="tempChartData && !loading">
        <v-card class="rounded-lg">
          <v-card-actions>
            <v-list-item class="grow">
              <span class="textcolor-sosclick">Temperatura</span>
            </v-list-item>
          </v-card-actions>
          <v-card-text>
            <LineChart class="mediciones" :chart-data="tempChartData" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6" v-if="oxiChartData && !loading">
        <v-card class="rounded-lg">
          <v-card-actions>
            <v-list-item class="grow">
              <span class="textcolor-sosclick">Saturación de oxigeno</span>
            </v-list-item>
          </v-card-actions>
          <v-card-text>
            <LineChart class="mediciones" :chart-data="oxiChartData" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6" v-if="pressureChartData && !loading">
        <v-card class="rounded-lg">
          <v-card-actions>
            <v-list-item class="grow">
              <span class="textcolor-sosclick">Presión Arterial</span>
            </v-list-item>
          </v-card-actions>
          <v-card-text>
            <LineChart class="mediciones" :chart-data="pressureChartData" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6" v-if="heartRateChartData && !loading">
        <v-card class="rounded-lg">
          <v-card-actions>
            <v-list-item class="grow">
              <span class="textcolor-sosclick">Ritmo Cardíaco</span>
            </v-list-item>
          </v-card-actions>
          <v-card-text>
            <LineChart class="mediciones" :chart-data="heartRateChartData" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" sm="12" v-if="electroChartData">
        <v-card class="rounded-lg">
          <v-card-actions>
            <v-list-item class="grow">
              <span class="textcolor-sosclick">Electro</span>
            </v-list-item>
          </v-card-actions>
          <v-card-text>
            <ElectroGraph class="mediciones" />
          </v-card-text>
        </v-card>
      </v-col>
      <!-- listado de mediciones -->
      <div
        v-if="historicData && !loading"
        class="d-flex align-center justify-center flex-wrap"
      >
        <v-col cols="12" md="12" sm="12">
          <h4 class="d-flex align-center">
            <v-icon>mdi-list-box</v-icon>
            <span class="headline textcolor-sosclick"
              >Listado de Mediciones</span
            >
          </h4>
          <!-- <h5>
            <span class="textcolor-sosclick">
              
            </span>
          </h5> -->
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
          lg="6"
          v-for="(data, index) in historicDataFiltered"
          :key="index"
        >
          <v-card class="rounded-lg elevation-1 mb-3" cols="3">
            <v-card-title>
              <v-list-item-title>
                <div>
                  <span class="color--grey lighten-1">
                    Smartband:
                    <span class="font-weight-bold">
                      {{ data.smartband && data.smartband.name }}
                    </span>
                  </span>
                </div>
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex align-center justify-end">
                <span class="mx-2">
                  <v-icon class="">mdi-calendar</v-icon>
                  {{ data.created_at | formatDate }}
                </span>
                <span class="mx-2">
                  <v-icon class="">mdi-clock</v-icon>
                  {{ data.created_at | formatHour }}
                </span>
              </v-list-item-subtitle>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  style="flex: 1; display: flex; flex-direction: column"
                >
                  <span style="align-self: center"> Presion Arterial </span>
                  <span style="align-self: center">
                    <span style="font-weight: bold">
                      {{ data.sistolica }}/{{ data.diastolica }}
                    </span>
                    mmHg
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  style="flex: 1; display: flex; flex-direction: column"
                >
                  <span style="align-self: center"> Ritmo Cardíaco </span>
                  <span style="align-self: center">
                    <span style="font-weight: bold">
                      {{ data.ritmo }}
                    </span>
                    min
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  style="flex: 1; display: flex; flex-direction: column"
                >
                  <span style="align-self: center"> Sat. de oxigeno </span>
                  <span style="align-self: center">
                    <span style="font-weight: bold">
                      {{ data.oxigeno }}
                    </span>
                    %
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </div>

      <v-col
        cols="12"
        md="12"
        sm="12"
        v-if="
          !tempChartData &&
          !oxiChartData &&
          !pressureChartData &&
          !heartRateChartData &&
          !electroChartData &&
          !loading
        "
      >
        <v-card class="rounded-lg">
          <v-card-text class="text-center font-weight-bold">
            No hay datos para mostrar en el rango de fechas seleccionado.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="loading" class="text-center h-300 align-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="50"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Medicion from "./Medicion";
import ElectroGraph from "./ElectroGraph";
import LineChart from "../Graphs/Line";
// import BarChart from "../Graphs/Bar";
import { getUserMediciones } from "../../helpers/api/mediciones";
import moment from "moment";

export default {
  name: "HistoricoMediciones",
  components: {
    // BarChart,
    ElectroGraph,
    LineChart,
  },
  data() {
    return {
      menu: false,
      alertDate: false,
      loading: false,
      dates: ["2023-01-01", moment().format("YYYY-MM-DD")],
      tempChartData: null,
      oxiChartData: null,
      pressureChartData: null,
      heartRateChartData: null,
      electroChartData: null,
      smartbands: [],
      smartband: "",
      historicData: [],
      historicDataFiltered: [],
      graphData: {},
			limit: 20
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" - ");
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    formatHour(value) {
      return moment(value).format("HH:mm");
    },
  },
  mounted() {
    this.height =
      this.$refs.containerInfo.clientHeight -
      this.$refs.headerInfo.clientHeight;
    this.getHistoricData();
  },
  methods: {
    filterBySmartband() {
      this.loading = true;
      this.oxiChartData = null;
      this.tempChartData = null;
      this.pressureChartData = null;
      this.heartRateChartData = null;
      if (this.smartband != "Todos") {
        this.historicDataFiltered = this.historicData
          .filter((medicion) => medicion.smartband.name === this.smartband)
          .sort((a, b) => b.created_at - a.created_at);
        this.oxiChartData = {
          labels: this.historicDataFiltered
            .filter((medicion) => medicion.oxigeno != null)
            .map((medicion) => moment(medicion.created_at).format("DD/MM")),
          datasets: [
            {
              data: this.historicDataFiltered
                .filter((medicion) => medicion.oxigeno != null)
                .map((medicion) => medicion.oxigeno),
              backgroundColor: "#ed8e00",
              pointRadius: 5,
              label: "Saturación",
              fill: false,
              borderColor: "#ed8e00",
            },
          ],
        };
        if (this.oxiChartData.labels.every((item) => item == null)) {
          this.oxiChartData = null;
        }
        this.tempChartData = {
          labels: this.historicDataFiltered
            .filter((medicion) => medicion.temperatura != null)
            .map((medicion) => moment(medicion.created_at).format("DD/MM")),
          datasets: [
            {
              data: this.historicDataFiltered
                .filter((medicion) => medicion.temperatura != null)
                .map((medicion) => medicion.temperatura),
              backgroundColor: "#007BED",
              pointRadius: 5,
              label: "Temperatura",
              fill: false,
              borderColor: "#007BED",
            },
          ],
        };
        if (this.tempChartData.labels.every((item) => item == null)) {
          this.tempChartData = null;
        }
        this.pressureChartData = {
          labels: this.historicDataFiltered
            .filter(
              (medicion) =>
                medicion.diastolica != null && medicion.sistolica != null
            )
            .map((medicion) => moment(medicion.created_at).format("DD/MM")),
          datasets: [
            {
              data: this.historicDataFiltered
                .filter((medicion) => medicion.diastolica != null)
                .map((medicion) => medicion.diastolica),
              backgroundColor: "#00ed77",
              pointRadius: 5,
              label: "Diastolica",
              fill: false,
              borderColor: "#00ed77",
            },
            {
              data: this.historicDataFiltered
                .filter((medicion) => medicion.sistolica != null)
                .map((medicion) => medicion.sistolica),
              backgroundColor: "#007BED",
              pointRadius: 5,
              label: "Sistolica",
              fill: false,
              borderColor: "#007BED",
            },
          ],
        };
        if (this.pressureChartData.labels.every((item) => item == null)) {
          this.pressureChartData = null;
        }
        this.heartRateChartData = {
          labels: this.historicDataFiltered
            .filter((medicion) => medicion.ritmo != null)
            .map((medicion) => moment(medicion.created_at).format("DD/MM")),
          datasets: [
            {
              data: this.historicDataFiltered
                .filter((medicion) => medicion.ritmo != null)
                .map((medicion) => medicion.ritmo),
              backgroundColor: "#007BED",
              pointRadius: 5,
              label: "Frecuencia cardiaca",
              fill: false,
              borderColor: "#007BED",
            },
          ],
        };
      } else {
        this.historicDataFiltered = this.historicData.sort(
          (a, b) => b.created_at - a.created_at
        );
        this.getChartsGraphs();
      }

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    getHistoricData() {
      this.loading = true;
      if (this.dates.length >= 2) {
        let dates = this.dates.sort();
        let start = dates[0].split("-").join("/");
        let end = dates[1].split("-").join("/");
        getUserMediciones({ start, end, limit: this.limit, type: 1 })
          .then((response) => {
            this.loading = false;

            this.historicData = response.respuesta;
            this.historicDataFiltered = this.historicData;
            this.graphData = response.graph;
            response.respuesta.length > 0
              ? response.respuesta.map(({ smartband }) => {
                  if (!this.smartbands.includes(smartband?.name)) {
                    this.smartbands.push(smartband?.name);
                  }
                })
              : (this.smartbands = []);
            this.getChartsGraphs();
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    },
    getChartsGraphs() {
      this.graphData.oxigeno.length > 0
        ? (this.oxiChartData = {
            labels: [...this.graphData.labels],
            datasets: [
              {
                data: [...this.graphData.oxigeno],
                backgroundColor: "#ed8e00",
                pointRadius: 5,
                label: "Saturación",
                fill: false,
                borderColor: "#ed8e00",
              },
            ],
          })
        : (this.oxiChartData = null);
      this.graphData.sistolica.length > 0
        ? (this.pressureChartData = {
            labels: [...this.graphData.labels],
            datasets: [
              {
                data: [...this.graphData.sistolica],
                backgroundColor: "#00ed77",
                pointRadius: 5,
                label: "Sistolica",
                fill: false,
                borderColor: "#00ed77",
              },
              {
                data: [...this.graphData.diastolica],
                backgroundColor: "#007BED",
                pointRadius: 5,
                label: "Diastolica",
                fill: false,
                borderColor: "#007BED",
              },
            ],
          })
        : (this.pressureChartData = null);
      this.graphData.ritmo.length > 0
        ? (this.heartRateChartData = {
            labels: [...this.graphData.labels],
            datasets: [
              {
                data: [...this.graphData.ritmo],
                backgroundColor: "#3d333d",
                pointRadius: 5,
                label: "Ritmo Cardíaco",
                fill: false,
                borderColor: "#3d333d",
              },
            ],
          })
        : (this.heartRateChartData = null);
      this.graphData.temperatura && this.graphData.temperatura.length > 0
        ? (this.tempChartData = {
            labels: [...this.graphData.labels],
            datasets: [
              {
                data: [...this.graphData.temperatura],
                backgroundColor: "#007BED",
                pointRadius: 5,
                label: "",
                fill: false,
                borderColor: "#007BED",
              },
            ],
          })
        : (this.tempChartData = null);
    },
  },
};
</script>

<style scoped>
.indicador {
  background: linear-gradient(180deg, white 50%, #f3f3f3 50%);
}

.icontainer {
  background: transparent;
}

.mediciones {
  height: 200px;
}
</style>
