<template>	
	<div v-if="plan">
		<v-img
			:src="require('../../assets/imgs/planes/'+plan.id+'@2x.png')"
			:height="bigger ? '240px':'175px'"
		/>
		<div class="py-2 px-5 mt-4">
			<p class="d-flex justify-center align-center headline text-center mb-0 textcolor-sosclick text-uppercase font-weight-regular">
				{{plan ? plan.name:''}}
				<v-icon class="ml-2" color="#ffd700" v-if="actual && actual.id === plan.id"> mdi-star </v-icon>
			</p>
			<p class="caption text-center mb-5" style="color: #838383;">{{plan.description}}</p>

			<p class="my-0">
				<v-icon color="#4AE01D" x-small>
					mdi-check
				</v-icon>
				<span class="caption ml-2" style="color: #838383;">Cantidad máxima de usuarios en red: {{plan.max_red}}</span>
			</p>

			<p class="my-0">
				<v-icon color="#4AE01D" x-small>
					mdi-check
				</v-icon>
				<span class="caption ml-2" style="color: #838383;">Cantidad máxima de dispositivos: {{plan.max_devices}}</span>
			</p>

			<p class="caption mt-2 mb-0 font-weight-medium" style="color: #838383;">Funcionalidades</p>
			<div class="mb-0 d-flex align-center" :key="index" v-for="(include,index) in plan.includes">
				<v-icon color="#4AE01D" x-small>
					mdi-check
				</v-icon>
				<div class="ml-2">
					<p class="caption mb-0" style="color: #838383;">{{includes ? getTextInclude(include):''}}</p>
				</div>
			</div>

			<p class="my-2">
				<v-icon color="#4AE01D" x-small>
					mdi-check
				</v-icon>
				<span class="caption ml-2" style="color: #838383;">Precio: {{toPrice(plan.price)}}</span>
			</p>
			<div class="d-flex flex-column justify-center align-center my-4">
				<v-select
					class="mb-2"
					v-model="toPayPlan.months_paid"
					:items="months"
					label="Meses a contratar"
					dense
					hide-details
					outlined
					rounded
					style="width: 300px;"
					@change="changeMonths"
				></v-select>
				<p class="font-weigth-bold mb-0 mx-4 text-center">Valor a Pagar: {{toPrice(toPayValue)}}</p>
				<p class="caption mb-0 mx-4 text-center">{{planText}}</p>
				<v-btn
					color="#007BED"
					depressed
					rounded
					:loading="loading"
					dark
					@click="startPayment()"
					class="mx-2"
					style="width: 300px;"
				>
					<p class="body-2 mb-0">{{actual && actual.id === plan.id ? 'Renovar':'Actualizar'}}</p>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import {getPaymentValue, startPaymentPlan, getOrderStatusByToken} from '../../helpers/api/planes'
	export default {
		name: 'Plan',
		props: ['plan', 'includes', 'bigger', 'actual'],
		data: () => ({
			loading: false,
			toPayPlan: {
				plan_id: 1,
				months_paid: 6
			},
			months: [1, 6, 12],
			planText: '',
			toPayValue: 0,
			requestedStatus: false
		}),
		mounted(){
			this.toPayPlan = {
				plan_id: this.plan.id,
				months_paid: 6
			}
			this.getPlanFinalValue()
			if(!this.requestedStatus && this.$route.query && this.$route.query.token){
				this.getOrderStatus(this.$route.query.token)
			}
		},
		methods: {
			changeMonths(value){
				this.toPayPlan = Object.assign({}, this.toPayPlan, {months_paid: value})
				this.getPlanFinalValue()
			},
			toPrice(price){
				var formatter = new Intl.NumberFormat('es-ES', {
					style: 'currency',
					currency: 'CLP',
					currencyDisplay: 'symbol'
				});
				return formatter.format(price)
			},
			getTextInclude(include){
				const includesData = this.includes.find((item) => {
					return item.id === include
				})
				return includesData.name
			},
			async getPlanFinalValue(){
				const price = await getPaymentValue(this.toPayPlan)
				this.toPayValue = price.amount
				this.planText = price.detail
			},
			async startPayment(){
				const planPayment = await startPaymentPlan(this.toPayPlan)
				if(planPayment.code === 200){
					if(planPayment.flowData && planPayment.flowData.url && planPayment.flowData.token){
						const payUrl = planPayment.flowData.url + '?token=' + planPayment.flowData.token
						window.location.href = payUrl
					}
				}
			},
			async getOrderStatus(order_token){
				this.requestedStatus = true
				const status = await getOrderStatusByToken({order_token})
				if(status.code === 200 && status.orderStatus){
					console.log(status.orderStatus)
					const data = {
						flowOrder: status.orderStatus.flowOrder,
						amount: status.orderStatus.amount,
						status: status.orderStatus.status,
						forma: status.orderStatus.paymentData ? status.orderStatus.paymentData.media:'',
						fecha: status.orderStatus.paymentData ? status.orderStatus.paymentData.date:''
					}
					this.$store.commit('setModalsStates', { paymentStatus: {open: true, data }})
				}
			}
		}
	};
</script>