<template>
	<v-row>
		<v-dialog
			v-model="dialog"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					class="mx-2"
					fab
					dark
					x-small
					color="grey"
				>
					<v-icon
						v-bind="attrs"
						v-on="on">
						mdi-fullscreen
					</v-icon>
				</v-btn>
			</template>
      <v-card height="100%">
        <v-btn
          fab
          small
          style="position: absolute; top: 20px; left: 20px; z-index: 99;"
					color="#007BED"
          @click="dialog = false"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
				<v-img
					contain
					height="100%"
					:src="lmpInfo.image_url"
				>
					<template v-slot:placeholder>
						<v-row
							class="fill-height ma-0"
							align="center"
							justify="center"
						>
							<v-progress-circular
								indeterminate
								color="#007BED"
							></v-progress-circular>
						</v-row>
					</template>
				</v-img>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
	export default {
		name: 'LMPModalImage',
		props: ['lmpInfo'],
		data: () => ({
      dialog: false,
			centerMap: {
				lat: -33.4373063,
				lng: -70.634297
			},
			mapZoom: 12
		}),
		mounted () {
		},
		methods: {
		}
	}
</script>
