<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props:['chartData'],
  data: () => ({
    options: {
      responsive: true, 
      maintainAspectRatio: false, 
      animation: {
        animateRotate: false
      },
      legend: {
        position: 'bottom'
      }
    }
  }),
  methods: {
    update() {
      this.$data._chart.update()
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
};
</script>