<template>
	<v-dialog
			v-model="$store.state.modalsStates.testReport"
			persistent
			max-width="800px"
			content-class="custom-dialog pa-4"
			fullscreen
			scrollable
		>
		<v-card>
			<v-btn
				icon
				color="#707070"
				@click="$store.commit('setModalsStates', {testReport: false})"
				style="position: absolute; top: 20px; right: 20px;"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12" style="color: #707070" class="text-center justify-center">
							<div style="background: #007BED;padding-top: 20px;max-width: 80%;margin: auto;border-radius: 20px;">
								<v-img
									:src="require('@/assets/imgs/logo-top.svg')"
									width="200"
									max-width="200"
									class="mx-auto mb-6 d-flex align-center justify-center"
									contain
								></v-img>
							</div>
							<h3 class="headline textcolor-sosclick">{{this.$store.state.reportData.result}}</h3>
							<p class="font-weight-bold mb-0">{{this.$store.state.reportData.atrial_fibrillation}}</p>
							<p class="font-weight-bold mb-0">{{this.$store.state.reportData.heart_rate}}</p>
							<div class="d-flex flex-column mt-2" style="overflow-y: scroll;">
								<p class="mb-0 text-center font-weight-bold" style="font-size: 12px;">Electrocardiograma completo</p>
								<ElectroGraph
									style="max-height: 200px;"
									v-for="(ecgChunk, index) in ecgData"
									:key="index"
									:chartData="ecgChunk" />
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<div class="text-center" style="width: 100%">
					<p class="caption my-4">Este reporte es generado por una plataforma de Inteligencia Artificial con los datos obtenidos de la medición del usuario.</p>
					<p class="font-weight-bold mb-0">Esta información es referencial.</p>
					<p class="font-weight-bold mb-0">Iduam no puede detectar signos de infarto.</p>
					<p class="font-weight-bold mb-0">Por favor siempre confirma tu estado de salud con un médico.</p>
				</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import ElectroGraph from "@/components/Mediciones/ElectroGraph";

export default {
	name: 'ModalReport',
	components: { ElectroGraph },
  computed: {
		ecgData () {
			const reportData = this.$store.state.reportData
			if(reportData && reportData.fullEcgData){
				let datos = [...reportData.fullEcgData]
				let graph = []
				const chunkSize = 200;
				for (let i = 0; i < datos.length; i += chunkSize) {
					const chunk = datos.slice(i, i + chunkSize)
					const graphDataset = {
						labels: chunk || [],
						datasets: [
							{
								data: chunk || [],
								fill: false,
								borderColor: "red",
							},
						],
					}
					graph.push(graphDataset)
				}
				return graph
			}
			return []
		},
  },
	data: () => ({
	}),
	mounted () {
	},
	methods: {
	}
}
</script>