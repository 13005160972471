import {endpoints} from './endpoints'
import request from '../request'

export function changeStatus (data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.changeSalesStatus,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getStatus (data){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getSalesStatus,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function getUserPaymentStatus (data){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getUserPaymentStatus,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}
export function getSalesResumen (data){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getSalesResumen,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}
export function getSalesData(){
	return new Promise((resolve) => {
		request({
			url: endpoints.getSalesData,
			method: 'post'
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			});
	})
}
export function updateBankAccount(data){
	return new Promise((resolve) => {
		request({
			url: endpoints.updateBankAccount,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			});
	})
}