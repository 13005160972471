<template>
  <v-container
    fluid
    fill-height
    class="grey lighten-4 py-0"
    style="max-height: 100%; overflow-y: scroll; align-items: flex-start"
  >
    <v-row ref="headerInfo">
      <v-col cols="12" sm="12">
        <h4 class="d-flex align-center">
          <v-img
            :src="require('../../assets/imgs/take-measurements.svg')"
            max-width="70"
            max-height="70"
          ></v-img>
          <span class="headline textcolor-sosclick" style="color: #707070"
            >Configurar Auto Medición</span
          >
        </h4>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        v-if="
          $store.state.userData.autoconfig &&
          !$store.state.userData.autoconfig.deleted_at
        "
      >
        <v-card>
          <!-- card que muestre name de smartband, time range y dispositivo -->
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <h3 class="font-size-36 text-center">
                  Ya tienes una configuración de auto medición
                </h3>
              </v-col>
              <v-col cols="9" sm="9">
                <p class="body-2">
                  <b>
                    <v-icon>mdi-watch-variant</v-icon>
                    Smartband:
                    {{ $store.state.userData.autoconfig.smartband.name }}
                  </b>
                  <br />
                  <b>
                    <v-icon>mdi-clock-outline</v-icon>
                    Frecuencia:
                    {{ $store.state.userData.autoconfig.timerange | timerange }}
                  </b>
                  <br />
                  <b>
                    <v-icon>mdi-devices</v-icon>
                    Dispositivo:
                    <!-- {{ $store.state.userData.autoconfig.device_id | device }} -->
                    {{ deviceName($store.state.userData.autoconfig.device_id) }}
                  </b>
                </p>
              </v-col>
              <v-col cols="3" sm="3" class="d-flex align-center">
                <v-btn class="mx-auto" color="primary" @click="deleteConfig">
                  <v-icon>mdi-delete</v-icon>
                  Eliminar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12">
        <v-card>
          <!-- card que muestre name de smartband, time range y dispositivo -->
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <h3 class="font-size-36 text-center">
                  Configura tu Auto Medición
                </h3>
              </v-col>
              <v-col cols="6" sm="3" md="3">
                <v-select
                  v-model="dispositivo"
                  :items="[...dispositivos]"
                  item-text="device_name"
                  item-value="device_id"
                  messages="Seleccione un Dispositivo"
                  label="Dispositivos"
                  class="mr-2"
                  no-data-text="No hay dispositivos"
                  :disabled="loading"
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" md="3">
                <v-select
                  v-model="smartband"
                  :items="[...smartbands]"
                  item-text="name"
                  item-value="id"
                  messages="Seleccione un Smartband"
                  label="Smartband"
                  class="mr-2"
                  no-data-text="No hay Smartbands"
                  :disabled="loading"
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" md="3">
                <v-select
                  v-model="frecuencia"
                  :items="[...frecuencias]"
                  item-text="text"
                  item-value="value"
                  messages="Seleccione una Frecuencia"
                  label="Frecuencia de Medicion"
                  class="mr-2"
                  :disabled="loading"
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="3"
                md="3"
                class="d-flex align-center justify-center"
              >
                <v-btn
                  color="primary"
                  class="mr-2"
                  @click="actualizarConfiguracion"
                  :disabled="
                    loading || !dispositivo || !smartband || !frecuencia
                  "
                >
                  <v-icon>mdi-cog</v-icon>
                  <span>Configurar</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAtenciones, getAtencionDetail } from "../../helpers/api/atenciones";
import {
  autoMedicion,
  deactivateAutoMedicion,
} from "../../helpers/api/mediciones";
import { getUser } from "../../helpers/api/user";

import moment from "moment";

export default {
  name: "AutoConfig",
  components: {},
  filters: {
    date: function (value) {
      if (!value) return "No registra";
      return moment(value).format("DD-MM-YYYY HH:mm");
    },
    timerange: function (value) {
      switch (value) {
        case 1:
          return "Cada 30 minutos";
        case 2:
          return "Cada 1 hora";
        case 3:
          return "Cada 6 horas";
        case 4:
          return "Cada 12 horas";
        case 5:
          return "1 vez al día";
        default:
          return "No registra";
      }
    },
  },
  data() {
    return {
      toModalData: null,
      atencionInfo: null,
      tableOptions: {
        page: 1,
        itemsPerPage: 15,
      },
      smartbands: [],
      smartband: "",
      dispositivos: [],
      dispositivo: "",
      frecuencias: [
        { text: "Cada 30 minutos", value: "1" },
        { text: "Cada 1 hora", value: "2" },
        { text: "Cada 6 horas", value: "3" },
        { text: "Cada 12 horas", value: "4" },
        { text: "1 vez al día", value: "5" },
      ],
      frecuencia: "",
      loading: false,
      atenciones: [],
      totalItems: 0,
      mediciones: {
        Temperatura: ["37°"],

        "Saturación de oxígeno": ["98", "%"],

        "Presión Arterial": ["128/78", "mmHg"],

        "Ritmo Cardíaco": ["120", "min"],
      },
    };
  },
  mounted() {
    this.getSmartbands();
    this.getDispositivos();
  },
  updated() {},
  methods: {
    deviceName(value) {
      if (!value) return "No registra";
      const device = this.$store.state.userData.dispositivos.find(
        (item) => item.id == value
      );
      return device.device_name || "No registra";
    },
    deleteConfig() {
      console.log("deleteConfig");
      this.loading = true;
      deactivateAutoMedicion().then((response) => {
        console.log(response);
        this.getUserData();
        this.loading = false;
      });
    },
    actualizarConfiguracion() {
      console.log(this.dispositivo, this.smartband, this.frecuencia);
      this.loading = true;
      autoMedicion({
        smartband_id: this.smartband,
        device_id: this.dispositivo,
        timerange: this.frecuencia,
      })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.getUserData();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    async getUserData() {
      await getUser()
    },
    getSmartbands() {
      console.log("getSmartbands", this.$store.state.userData.smartbands);
      this.smartbands = [...this.$store.state.userData.smartbands];
    },
    getDispositivos() {
      this.dispositivos = [...this.$store.state.userData.dispositivos];
    },
    rotateVideo() {
      this.rotate >= 3 ? (this.rotate = 0) : (this.rotate += 1);
    },
    getDuration(startDate, endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const seconds = end.diff(start, "seconds");
      const result = moment()
        .startOf("day")
        .seconds(seconds)
        .format("HH:mm:ss");
      return result;
    },
    getItemsClass(item) {
      let className = "custom-items-table";
      if (this.atencionInfo && this.atencionInfo.id === item.id) {
        className += " selected-row-custom";
      }
      return className;
    },
    paginate(data) {
      this.getUserAtenciones(data);
    },
    detailAtencion(item) {
      this.toModalData = item;
    },
    async getUserAtenciones(query) {
      this.loading = true;
      const data = {
        limit: query.itemsPerPage,
        page: query.page,
      };
      const atenciones = await getAtenciones(data);
      console.log("atenciones: ", atenciones);
      this.atenciones = atenciones.atenciones;
      this.totalItems = atenciones.total_atenciones;
      this.loading = false;
    },
    async getDetails(atencion) {
      const atencionInfo = await getAtencionDetail({
        atencion_id: atencion.id,
      });
      this.atencionInfo = atencionInfo.atencion;
      const forceRefreshDataTable = [...this.atenciones];
      this.atenciones = forceRefreshDataTable;
    },
  },
};
</script>

<style scoped>
.indicador {
  background: linear-gradient(180deg, white 50%, #f3f3f3 50%);
}

.icontainer {
  background: transparent;
}
</style>
