<script>
import { Line } from "vue-chartjs";

export default {
  name: "ElectroGraph",
  extends: Line,
  props:['chartData'],
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: { enabled: false },
      plugins: {
        legend: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              display: false,
            },
          },
        ],
      },
    },
  }),
  methods: {
    update() {
      this.$data._chart.update()
    }
  },
  mounted() {
		setTimeout(() => {
		this.renderChart(this.chartData, this.options);

		}, 500)
  },
};
</script>

<style></style>
