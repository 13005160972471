var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height buttons-home-container justify-center py-0"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center align-center flex-wrap",class:_vm.$vuetify.breakpoint.mobile ? 'mobile-container-home-grid' : ''},[_c('div',{staticClass:"my-2 mx-6 text-center",style:(_vm.$vuetify.breakpoint.mobile
              ? 'padding: 5px;width: 50%;'
              : 'width:160px;')},[_c('v-btn',{staticClass:"d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto",attrs:{"depressed":"","large":"","tile":"","elevation":"2"},on:{"click":function($event){return _vm.$router.push({ name: 'Mediciones' })}}},[_c('v-img',{attrs:{"src":require('../../assets/imgs/take-measurements.svg'),"width":"70","height":"70","max-width":"70","max-height":"70"}})],1),_c('p',{staticClass:"mb-0 body-2",staticStyle:{"color":"#707070"}},[_vm._v("Tomar Mediciones")])],1),_c('div',{staticClass:"my-2 mx-6 text-center",style:(_vm.$vuetify.breakpoint.mobile
              ? 'padding: 5px;width: 50%;'
              : 'width:170px;')},[_c('v-btn',{staticClass:"d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto",attrs:{"depressed":"","large":"","tile":"","elevation":"2"},on:{"click":function($event){return _vm.$router.push({ name: 'AutoConfig' })}}},[_c('v-img',{attrs:{"src":require('../../assets/imgs/take-measurements.svg'),"width":"70","height":"70","max-width":"70","max-height":"70"}})],1),_c('p',{staticClass:"mb-0 body-2",staticStyle:{"color":"#707070"}},[_vm._v("Configurar Auto Medición")])],1),_c('div',{staticClass:"my-2 mx-6 text-center",style:(_vm.$vuetify.breakpoint.mobile
              ? 'padding: 5px;width: 50%;'
              : 'width:160px;')},[_c('v-btn',{staticClass:"d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto",attrs:{"depressed":"","large":"","tile":"","elevation":"2"},on:{"click":function($event){return _vm.$store.commit('setModalsStates', { securityNet: true })}}},[_c('v-img',{attrs:{"src":require('../../assets/imgs/security-net.svg'),"width":"70","height":"70","max-width":"70","max-height":"70"}})],1),_c('p',{staticClass:"mb-0 body-2",staticStyle:{"color":"#707070"}},[_vm._v("Red de Salud")])],1),_c('div',{staticClass:"my-2 mx-6 text-center",style:(_vm.$vuetify.breakpoint.mobile
              ? 'padding: 5px;width: 50%;'
              : 'width:160px;')},[_c('v-btn',{staticClass:"d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto",attrs:{"depressed":"","large":"","tile":"","elevation":"2"},on:{"click":function($event){return _vm.$router.push({ name: 'HistoricoMediciones' })}}},[_c('v-img',{attrs:{"src":require('../../assets/imgs/historic-measurements.svg'),"width":"70","height":"70","max-width":"70","max-height":"70"}})],1),_c('p',{staticClass:"mb-0 body-2",staticStyle:{"color":"#707070"}},[_vm._v(" Histórico de Mediciones ")])],1),_c('div',{staticClass:"my-2 mx-6 text-center",style:(_vm.$vuetify.breakpoint.mobile
              ? 'padding: 5px;width: 50%;'
              : 'width:160px;')},[_c('v-btn',{staticClass:"d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto",attrs:{"depressed":"","large":"","tile":"","elevation":"2"},on:{"click":function($event){return _vm.$router.push({ name: 'Atenciones' })}}},[_c('v-img',{attrs:{"src":require('../../assets/imgs/user.svg'),"width":"70","height":"70","max-width":"70","max-height":"70"}})],1),_c('p',{staticClass:"mb-0 body-2",staticStyle:{"color":"#707070"}},[_vm._v(" Histórico de Atenciones ")])],1),_c('div',{staticClass:"my-2 mx-6 text-center",style:(_vm.$vuetify.breakpoint.mobile
              ? 'padding: 5px;width: 50%;'
              : 'width:160px;')},[_c('v-btn',{staticClass:"d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto",attrs:{"depressed":"","large":"","tile":"","elevation":"2"},on:{"click":function($event){return _vm.$router.push({ name: 'Planes' })}}},[_c('v-img',{attrs:{"src":require('../../assets/imgs/plans.svg'),"width":"70","height":"70","max-width":"70","max-height":"70"}})],1),_c('p',{staticClass:"mb-0 body-2",staticStyle:{"color":"#707070"}},[_vm._v("Mis planes")])],1),_c('div',{staticClass:"my-2 mx-6 text-center",style:(_vm.$vuetify.breakpoint.mobile
              ? 'padding: 5px;width: 50%;'
              : 'width:160px;')},[_c('v-btn',{staticClass:"d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto",attrs:{"depressed":"","large":"","tile":"","disabled":!_vm.$store.state.selectedDevice ||
                !(
                  _vm.$store.state.selectedDevice &&
                  _vm.$store.state.selectedDevice.device_so === 'android'
                ),"elevation":"2"},on:{"click":function($event){return _vm.$router.push({ name: 'Information' })}}},[_c('v-img',{attrs:{"src":require('../../assets/imgs/device-tracking.svg'),"width":"70","height":"70","max-width":"70","max-height":"70"}})],1),_c('p',{staticClass:"mb-0 body-2",staticStyle:{"color":"#707070"}},[_vm._v(" Tracking del dispositivo ")])],1)])]),_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center align-center flex-wrap",class:_vm.$vuetify.breakpoint.mobile ? 'mobile-container-home-grid' : ''}),_c('div',{class:_vm.$vuetify.breakpoint.mobile ? 'mt-3' : 'nodevice-text'},[_c('div',{staticClass:"text-center d-flex justify-center align-center",staticStyle:{"color":"#707070"}},[_c('v-icon',{staticClass:"mx-2",attrs:{"small":"","color":"#007BED"}},[_vm._v("mdi-information-outline")]),(!_vm.$store.state.selectedDevice)?_c('p',{staticClass:"text-center mb-0 body-2 caption"},[_vm._v(" Selecciona un dispositivo en el menú lateral para acceder a las funcionalidades deshabilitadas ")]):_c('p',{staticClass:"text-center mb-0 body-2 caption"},[_vm._v(" Dispositivo seleccionado: "),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$store.state.selectedDevice.device_brand)+" "+_vm._s(_vm.$store.state.selectedDevice.device_name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(this.$store.state.selectedDevice.device_so === 'ios'),expression:"this.$store.state.selectedDevice.device_so === 'ios'"}]},[_vm._v(" | Algunas funcionalidades sólo están disponibles en dispositivos Android")])])],1)])])],1),_c('div',{staticClass:"py-5",class:!_vm.$vuetify.breakpoint.mobile ? 'foot-info' : 'text-center'},[_c('a',{staticClass:"mb-0 body-2 caption",staticStyle:{"color":"#707070"}},[_vm._v("Política de privacidad | Términos y condiciones | Copyright © 2020 IDUAM. Todos los derechos reservados.")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }