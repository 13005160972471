<template>
  <v-container fluid style="overflow-y: scroll;" class="fill-height align-start bg-grandient">
    <v-snackbar
      v-model="showMessages"
      vertical
      top
      right
    >
      <p class="mb-0">Hay errores en el formulario:</p>
      <p
        v-for="(msg, index) in messages"
        :key="index"
        class="mb-0 d-block"
        >{{(index + 1) + ': ' + msg}}</p>
    </v-snackbar>
    <v-row class="text-center">
      <v-col cols="12">
        <div class="mx-auto" style="max-width: 600px;">
            <div style="display:flex;gap: 20px; align-items:center;padding: 20px; flex-wrap:wrap;">
          <v-img
            :src="require('../../assets/logo-iduam.svg')"
            class="my-3"
            contain
            style="width:20%"
          />
          <v-img
            :src="require('../../assets/imgs/logo-valcar.svg')"
            class="my-3"
            contain
            style="width:15%"
          />
          <v-img
            :src="require('../../assets/logo-arica.png')"
            class="my-3"
            contain
            style="width:10%"
          />
          <v-img
            :src="require('../../assets/logo-core.png')"
            class="my-3"
            contain
            style="width:27%"
          />
            </div>
          <div v-if="registerOK">
            <v-img src="../../assets/imgs/shield.svg" max-height="200px" contain></v-img>
            <p class="white--text my-5 display-1">Pre-Registro Completo</p>
<!--             <p class="white--text mb-0">Estás sólo a un paso de estar protegido.</p>
            <p class="white--text">Descarga la aplicación desde Play Store o App Store.</p> -->
<!--             <v-btn
              color="white"
              rounded
              outlined
              large
              @click="loginUser"
              style="width: 200px;"
              class="mx-2 my-5"
              :class="$vuetify.breakpoint.mobile ? 'mb-3':''"
              :block="$vuetify.breakpoint.mobile"
            >
              <p class="body-2 mb-0" >Ir al panel de usuario</p>
            </v-btn> -->
<!--             <v-row class="text-center align-center">
              <v-col cols="6">
                <a href="https://play.google.com/store/apps/details?id=com.naturalphone.sosclick" target="_blank">
                  <v-img
                    style="max-width: 100%"
                    src="../../assets/imgs/google-play-badge.png" contain></v-img>
                </a>
              </v-col>
              <v-col cols="6">
                <a href="https://apps.apple.com/us/app/sosclick/id1517076968" target="_blank">
                  <v-img
                    style="max-width: 100%"
                    src="../../assets/imgs/Download_on_the_App_Store_Badge_ES_RGB_blk_100217.svg" contain></v-img>
                </a>
              </v-col>
            </v-row> -->
          </div>
          <v-form
            v-else
            ref="form"
            class="custom-form-registro"
            v-model="valid"
          >
            <div class="text-center form-container">
              <p class="white--text mb-0" style="font-size: 20px; font-weight:500;text-align: center;">Registro previo y necesario para acceder al programa “Estudio Estadístico de Salud para la Región de Arica Parinacota”, Código BIP 40049734-0. El cual entregará una Pulsera inteligente de medición de parámetros de Salud. Programa financiado por el Gobierno Regional de Arica y Parinacota y el Consejo Regional.</p>
                  <!-- <p>Prerregistro para Programa Gobierno Regional de Arica y Parinacota.</p> -->
              <p class="white--text mb-0" style="margin-top: 8px; text-align: justify;">Para acceder al programa debe llenar todos los datos requeridos y será contactado para coordinar su entrega.</p>
              <p class="white--text" v-if="userInvite">
                <span v-if="codeType === 1">Vas a ser agregado a la red de seguridad de </span>
                <span v-if="codeType === 2">Estás usando el código de referido de </span>
                <b>{{userInvite.name}}</b>
              </p>
              <v-text-field
              class="mt-2"
                v-model="userInfo.name"
                :rules="required"
                label="Nombre"
                required
                dark
              ></v-text-field>
              <v-text-field
                v-model="userInfo.rut"
                :rules="rut"
                label="RUT"
                required
                @change="(rut) => {formatRut(rut)}"
                dark
              ></v-text-field>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate"
                    label="Fecha de nacimiento"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dark
                    :rules="[v => !!v || 'Campo requerido']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="userInfo.birth_date"
                  @input="menu2 = false"
                  max="2009-01-01"
                  min="1900-01-01"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                v-model="userInfo.email"
                :rules="isEmailValid"
                @change="(email) => {isEmail(email)}"
                required
                label="Email"
                dark
              ></v-text-field>
              <v-select
                v-model="userInfo.disease"
                :items="diseases"
                :rules="required"
                item-text="name"
                item-value="id"
                label="Enfermedad Crónica"
                dark
                multiple
              ></v-select>
              <p style="color: #FFF; font-size: 14px; text-align:left; margin-bottom: 32px;">* Puedes seleccionar más de 1 opción. Se priorizará a los usuarios que tengan mayor cantidad de enfermedades/condiciones demostrables.</p>
              <v-select
                v-model="userInfo.pais"
                :items="paises"
                :rules="required"
                item-text="name"
                item-value="id"
                hint="Se muestran sólo los países en los que Iduam está disponible"
                persistent-hint
                label="Países"
                dark
                disabled
                @change="getRegion"
              ></v-select>
              <v-select
                v-model="userInfo.region"
                :items="regiones"
                :rules="required"
                item-text="region"
                item-value="id"
                hint="Se muestran sólo las regiones en las que Iduam está disponible"
                persistent-hint
                label="Región"
                dark
                disabled
                @change="getComunasActivas"
              ></v-select>
              <v-select
                v-if="comunas.length > 0"
                v-model="userInfo.comuna"
                :items="comunas"
                :rules="required"
                item-text="comuna"
                item-value="id"
                hint="Se muestran sólo las comunas en las que Iduam está disponible"
                persistent-hint
                label="Comuna"
                dark
              ></v-select>
              <v-text-field
                v-model="userInfo.address"
                :rules="required"
                label="Dirección"
                required
                dark
              ></v-text-field>
              <div style="display:flex; align-items:end;gap:8px;">
                <p style="margin-bottom:25px;color:#FFF;">+569</p>
              <v-text-field
                v-model="userInfo.phone"
                :rules="requiredPhone"
                label="Teléfono"
                :counter="8"
                maxlength="8"
                required
                dark
              ></v-text-field>
              </div>
              <div>
          <v-radio-group v-model="userInfo.social" row>
            <template v-slot:label>
              <div style="color: #FFF; margin-right: 8px;"><strong>¿Tiene Cartola del Registro Social de Hogares?</strong></div>
            </template>
            <v-radio
            color="white"
              label="Si"
              :value="true"
            ></v-radio>
            <v-radio
            color="white"
              label="No"
              :value="false"
            ></v-radio>
          </v-radio-group>
              </div>
              <v-btn class="mt-4" depressed dark block @click="() => registro()">Pre-Registrar</v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
            <div class="container-descargabtns">
        <a target="blank" href="https://apps.apple.com/us/app/iduam/id1529934806">
          <img src="../../assets/imgs/appstore.png" alt="" />
        </a>
        <a target="blank" href="https://play.google.com/store/apps/details?id=com.naturalphone.sosclickiduam">
          <img src="../../assets/imgs/googleplay.png" alt="" />
        </a>
          </div>


      <!-- DIALOG INICIAL RUT -->
      <v-dialog v-model="dialog" width="500" persistent>
      <v-card style="background-color: #007BED; padding: 20px;">
        <v-select
          v-model="userInfo.disease"
          :items="diseases"
          :rules="required"
          item-text="name"
          item-value="id"
          label="Enfermedad Crónica"
          dark
          multiple
        ></v-select>

        <div>
          <v-radio-group v-model="userInfo.social" row>
            <template v-slot:label>
              <div style="color: #FFF; margin-right: 8px;"><strong>¿Tiene Cartola del Registro Social de Hogares?</strong></div>
            </template>
            <v-radio
            color="white"
              label="Si"
              :value="true"
            ></v-radio>
            <v-radio
            color="white"
              label="No"
              :value="false"
            ></v-radio>
          </v-radio-group>
                        <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate"
                    label="Fecha de nacimiento"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dark
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="userInfo.birth_date"
                  @input="menu3 = false"
                  max="2009-01-01"
                  min="1900-01-01"
                ></v-date-picker>
              </v-menu>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div style="display: flex; align-items: center; justify-content: space-between; width:100%;">
        <p style="color: #FFF;">
          * Rellene todos los campos
        </p>
          <v-btn
            :disabled="!userInfo?.disease?.length || !userInfo?.birth_date"
            color="white"
            text
            @click="registro('keep'); dialog = false"
          >
            Pre-Registrar
          </v-btn>
          </div>
        </v-card-actions>
      </v-card>
      </v-dialog>


      <!-- DIALOG AVISO ENFERMEDAD -->
      <v-dialog v-model="dialogAlertDisease" width="500" persistent>
      <v-card style="background-color: #007BED; padding: 20px;">
        <p style="color: #FFF;">
          * Sólo ha seleccionado una enfermedad crónica, en caso de padecer más enfermedades, puede seleccionarlas a continuación. Se priorizará a los usuarios que tengan mayor cantidad de enfermedades/condiciones demostrables.
        </p>

        <v-select
          v-model="userInfo.disease"
          :items="diseases"
          :rules="required"
          item-text="name"
          item-value="id"
          label="Enfermedad Crónica"
          dark
          multiple
        ></v-select>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            text
            @click="registro(); dialogAlertDisease = false"
          >
            Pre-Registrar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
  import { validate, format } from 'rut.js'
   import moment from 'moment'
  import {paisesActivos, regionesActivas, comunasActivas, preRegister, getPreregisterUser } from '../../helpers/api/user'

  export default {
    name: 'Register',
    data: () => ({
      menu: false,
      showMessages: false,
      messages: null,
      showPass: false,
      registerOK: false,
			diseases: [
				{name: 'Ninguna', id: 'Ninguna'},
				{name: 'Hipertensión', id: 'Hipertensión'},
				{name: 'Diabetes', id: 'Diabetes'},
				{name: 'Obesidad', id: 'Obesidad'},
				{name: 'Hipotiroidismo', id: 'Hipotiroidismo'},
				{name: 'Crisis de pánico', id: 'Crisis de pánico'},
				{name: 'Depresión', id: 'Depresión'},
				{name: 'Cardiopatía crónica', id: 'Cardiopatía crónica'},
				{name: 'Enfermedades Cardiovasculares', id: 'Enfermedades Cardiovasculares'},
				{name: 'Cirrosis y otras Enfermedades del Hígado', id: 'Cirrosis y otras Enfermedades del Hígado'},
				{name: 'Enfermedades Crónicas de las Vías Respiratorias', id: 'Enfermedades Crónicas de las Vías Respiratorias'},
				{name: 'Enfermedades Crónicas del Sistema Urinario', id: 'Enfermedades Crónicas del Sistema Urinario'},
				{name: 'Enfermos postrados', id: 'Enfermos postrados'},
				{name: 'Pacientes crónicos con movilidad reducida', id: 'Pacientes crónicos con movilidad reducida'},
				{name: 'Covid-19', id: 'Covid-19'},
			],
      paises: [],
      regiones: [],
      comunas: [],
      userInfo: {
        name: '',
        rut: '',
        phone: '',
        email: '',
        pais: 81,
        region: 1,
        comuna: null,
        address: '',
				disease: null,
        social: false,
        birth_date: null
      },
      dialog: false,
      dialogAlertDisease: false,
      menu2: null,
      menu3: null,
      valid: false,
      required: [
        v => !!v || 'Este campo es obligatorio'
      ],
      rut: [
        v => (!!v || 'Este campo es obligatorio') && (validate(v) || 'Rut no válido')
      ],
      requiredPhone: [
        v => !!v || 'Este campo es obligatorio',
        v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números'
      ],
      isEmailValid: [
        //eslint-disable-next-line
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Formato incorrecto'
      ],
      items: [{text: 'Femenino', value: 2}, {text: 'Masculino', value: 1}],
      userInvite: null,
      codeType: 1
    }),
    computed: {
      formatDate() {
        return this.userInfo.birth_date ? this.userInfo.birth_date  : ''
      }
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
      'userInfo.disease' : function(newVal){
        if ( newVal.length > 1 && newVal.includes('Ninguna')) {
          this.userInfo.disease = this.userInfo.disease.filter(e => e == 'Ninguna')

        if (newVal.length > 1 && newVal.includes('Ninguna')) {
          this.userInfo.disease = this.userInfo.disease.filter(e => e !== 'Ninguna')
          } 
        }
     }
    },
    methods: {
      formatRut(rut) {
        this.userInfo.rut = format(rut)
      },    
      isEmail(email){
        //eslint-disable-next-line
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
      },
      async getPaises(){
        const paises = await paisesActivos()
        this.paises = paises.paises
      },
      async getRegion(pais_id){
        const regiones = await regionesActivas({pais_id})
        this.regiones = regiones.regiones
      },
      async getComunasActivas(region_id){
        const comunas = await comunasActivas({region_id})
        this.comunas = comunas.comunas
      },
      async registro(keep){
        if(this.$refs.form.validate()){
          const self = this

        const data = {...this.userInfo}
        data.company_id = 1
        data.disease = data.disease.join(',')
        data.birthday = this.userInfo.birth_date

        if (!this.dialog && !this.dialogAlertDisease && this.userInfo.disease.length == 1) {
          this.dialogAlertDisease = true;
        } else {
          preRegister(data).then(async function () {
            if (!keep) {
              self.registerOK = true
            }
            })
            .catch(function (error) {
              self.messages = Object.values(error)
              self.showMessages = true
              console.log(self.messages)
            });
        }
        }
      },
    },
    mounted(){
      this.getPaises()
      this.getRegion(81)
      this.getComunasActivas(1)
      
      const rut = this.$route.query.rut
      if (rut) {
        getPreregisterUser({ rut }).then(res => {
          const { name, rut, phone, email, pais, region, comuna, address, disease, social, birthday } = res.user;
            this.userInfo = {
              name,
              rut,
              phone,
              email,
              pais,
              region,
              comuna,
              address,
              disease: disease ? disease.split(',') : [],
              social: social || false,
              birth_date: birthday ? moment(birthday).format('YYYY-MM-DD') : null
            }

            this.dialog = true;
        })
      }
    },
  }
</script>
<style type="text/css" media="screen">
  .v-application .custom-form-registro .error--text {
    color: white !important;
    caret-color: #f2f2f2 !important;
  }
  .bg-grandient {
     background: linear-gradient(to top left, #007bed 60%, #f3af3d 50%);
  }
  .form-container {
    background-color: #007bed;
    border: 1.3px solid #FFF;
    border-radius: 15px;
    padding: 30px;
    opacity: .8;
  }

  @media (max-width: 568px) {
    .form-container{
        padding: 12px;
    }
  }

.container-descargabtns {
  margin: 0 auto;
display: flex;
justify-content: center;
flex-wrap: wrap;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .container-descargabtns {
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 0;
  }
}
#checkboxlabel {
   color: #fff;
   font-size: 16px;
}
#checkboxlabel2 {
   color: #fff;
   font-size: 16px;
}
#checkboxlabel {
   color: #fff;
   font-size: 16px;
}
</style>