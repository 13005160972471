<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props:['chartData'],
  data: () => ({
    options: {
      responsive: true, 
      maintainAspectRatio: false, 
      animation: {
        animateRotate: false,
      },
      scales: {
        yAxes: [{
          ticks: {
            precision: 0,
            // suggestedMin: 0
          }
        }]
      }
    }
  }),
  methods: {
    update() {
      this.$data._chart.update()
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>