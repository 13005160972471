<template>
  <v-container class="fill-height buttons-home-container justify-center py-0">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div
          class="d-flex justify-center align-center flex-wrap"
          :class="
            $vuetify.breakpoint.mobile ? 'mobile-container-home-grid' : ''
          "
        >
          <div
            class="my-2 mx-6 text-center"
            :style="
              $vuetify.breakpoint.mobile
                ? 'padding: 5px;width: 50%;'
                : 'width:160px;'
            "
          >
            <v-btn
              depressed
              large
              tile
              elevation="2"
              @click="$router.push({ name: 'Mediciones' })"
              class="d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto"
            >
              <v-img
                :src="require('../../assets/imgs/take-measurements.svg')"
                width="70"
                height="70"
                max-width="70"
                max-height="70"
              ></v-img>
            </v-btn>
            <p class="mb-0 body-2" style="color: #707070">Tomar Mediciones</p>
          </div>
          <div class="my-2 mx-6 text-center"
            :style="
                $vuetify.breakpoint.mobile
                ? 'padding: 5px;width: 50%;'
                : 'width:170px;'            "
          >
            <v-btn
              depressed
              large
              tile
              elevation="2"
              @click="$router.push({ name: 'AutoConfig' })"
              class="d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto"
            >
              <v-img
                :src="require('../../assets/imgs/take-measurements.svg')"
                width="70"
                height="70"
                max-width="70"
                max-height="70"
              ></v-img>
            </v-btn>
            <p class="mb-0 body-2" style="color: #707070">Configurar Auto Medición</p>
          </div>
          <div
            class="my-2 mx-6 text-center"
            :style="
              $vuetify.breakpoint.mobile
                ? 'padding: 5px;width: 50%;'
                : 'width:160px;'
            "
          >
            <v-btn
              depressed
              large
              tile
              elevation="2"
              class="d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto"
              @click="$store.commit('setModalsStates', { securityNet: true })"
            >
              <v-img
                :src="require('../../assets/imgs/security-net.svg')"
                width="70"
                height="70"
                max-width="70"
                max-height="70"
              ></v-img>
            </v-btn>
            <p class="mb-0 body-2" style="color: #707070">Red de Salud</p>
          </div>
          <div
            class="my-2 mx-6 text-center"
            :style="
              $vuetify.breakpoint.mobile
                ? 'padding: 5px;width: 50%;'
                : 'width:160px;'
            "
          >
            <v-btn
              depressed
              large
              tile
              elevation="2"
              class="d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto"
              @click="$router.push({ name: 'HistoricoMediciones' })"
            >
              <v-img
                :src="require('../../assets/imgs/historic-measurements.svg')"
                width="70"
                height="70"
                max-width="70"
                max-height="70"
              ></v-img>
            </v-btn>
            <p class="mb-0 body-2" style="color: #707070">
              Histórico de Mediciones
            </p>
          </div>
          <div
            class="my-2 mx-6 text-center"
            :style="
              $vuetify.breakpoint.mobile
                ? 'padding: 5px;width: 50%;'
                : 'width:160px;'
            "
          >
            <v-btn
              depressed
              large
              tile
              elevation="2"
              class="d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto"
              @click="$router.push({ name: 'Atenciones' })"
            >
              <v-img
                :src="require('../../assets/imgs/user.svg')"
                width="70"
                height="70"
                max-width="70"
                max-height="70"
              ></v-img>
            </v-btn>
            <p class="mb-0 body-2" style="color: #707070">
              Histórico de Atenciones
            </p>
          </div>
                    <div
            class="my-2 mx-6 text-center"
            :style="
              $vuetify.breakpoint.mobile
                ? 'padding: 5px;width: 50%;'
                : 'width:160px;'
            "
          >
            <v-btn
              depressed
              large
              tile
              elevation="2"
              class="d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto"
              @click="$router.push({ name: 'Planes' })"
            >
              <v-img
                :src="require('../../assets/imgs/plans.svg')"
                width="70"
                height="70"
                max-width="70"
                max-height="70"
              ></v-img>
            </v-btn>
            <p class="mb-0 body-2" style="color: #707070">Mis planes</p>
          </div>
                    <div
            class="my-2 mx-6 text-center"
            :style="
              $vuetify.breakpoint.mobile
                ? 'padding: 5px;width: 50%;'
                : 'width:160px;'
            "
          >
            <v-btn
              depressed
              large
              tile
              :disabled="
                !$store.state.selectedDevice ||
                  !(
                    $store.state.selectedDevice &&
                    $store.state.selectedDevice.device_so === 'android'
                  )
              "
              elevation="2"
              class="d-flex text-white py-5 px-5 rounded-xl buttons-home mb-3 mx-auto"
              @click="$router.push({ name: 'Information' })"
            >
              <v-img
                :src="require('../../assets/imgs/device-tracking.svg')"
                width="70"
                height="70"
                max-width="70"
                max-height="70"
              ></v-img>
            </v-btn>
            <p class="mb-0 body-2" style="color: #707070">
              Tracking del dispositivo
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" style="position: relative;">
        <div
          class="d-flex justify-center align-center flex-wrap"
          :class="
            $vuetify.breakpoint.mobile ? 'mobile-container-home-grid' : ''
          "
        >

        </div>
        <div :class="$vuetify.breakpoint.mobile ? 'mt-3' : 'nodevice-text'">
          <div
            class="text-center d-flex justify-center align-center"
            style="color: #707070"
          >
            <v-icon small color="#007BED" class="mx-2"
              >mdi-information-outline</v-icon
            >
            <p
              v-if="!$store.state.selectedDevice"
              class="text-center mb-0 body-2 caption"
            >
              Selecciona un dispositivo en el menú lateral para acceder a las
              funcionalidades deshabilitadas
            </p>
            <p v-else class="text-center mb-0 body-2 caption">
              Dispositivo seleccionado:
              <span class="text-capitalize"
                >{{ $store.state.selectedDevice.device_brand }}
                {{ $store.state.selectedDevice.device_name }}</span
              >
              <span
                v-show="this.$store.state.selectedDevice.device_so === 'ios'"
              >
                | Algunas funcionalidades sólo están disponibles en dispositivos
                Android</span
              >
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <div
      class="py-5"
      :class="!$vuetify.breakpoint.mobile ? 'foot-info' : 'text-center'"
    >
      <a class="mb-0 body-2 caption" style="color: #707070"
        >Política de privacidad | Términos y condiciones | Copyright © 2020
        IDUAM. Todos los derechos reservados.</a
      >
    </div>
  </v-container>
</template>

<script>
import { getUser } from "../../helpers/api/user";

export default {
  name: "Home",
  data: () => ({}),
  mounted() {
    this.getUserData();
    this.$store.commit('setShowNavbar', true)
  },
  methods: {
    openModalRestricted(type) {
      if (
        !this.$store.state.selectedDevice ||
        !(
          this.$store.state.selectedDevice &&
          this.$store.state.selectedDevice.device_so === "android"
        )
      ) {
        this.$store.commit("setModalsStates", { notAllowed: true });
        return false;
      }
      switch (type) {
        case 1:
          this.$store.commit("setModalsStates", { lockDevice: true });
          break;
        case 2:
          this.$store.commit("setModalsStates", { lostPhone: true });
          break;
        case 3:
          this.$store.commit("setModalsStates", { wipeData: true });
          break;
        default:
          break;
      }
    },
    async getUserData() {
      if (!this.$store.state.userData) {
        await getUser();
      }
    },
  },
};
</script>
