<template>
	<div style="height: 100vh;" v-if="this.$store.state.isAuthenticated && $route.name != 'Reestablecer' && $route.name != 'PreRegistro' && $route.name !== 'Register'">
		<Header v-if="$store.state.showNavbar"/>
		<v-container fluid class="grey lighten-4 py-0" style="height: calc(100vh - 92px);">
			<v-row class="fill-height">
				<v-navigation-drawer
						v-model="$store.state.drawer"						
						:permanent="!$vuetify.breakpoint.mobile"
						:touchless="!$vuetify.breakpoint.mobile"
						:absolute="$vuetify.breakpoint.mobile"
						:top="$vuetify.breakpoint.mobile"
						:temporary="$vuetify.breakpoint.mobile"
						:width="$vuetify.breakpoint.mobile? 256 : '25%'"
					>
					<SidebarContent />
				</v-navigation-drawer>
				<v-col 
					:cols="$vuetify.breakpoint.mobile ? 12:9"
					class="py-0"
					style="max-height: 100%;"
					:style="!$vuetify.breakpoint.mobile ? '':'overflow-y: scroll;'">
					<router-view></router-view>
				</v-col>
			</v-row>
		</v-container>
	</div>
	<div style="height: 100vh;" v-else>
		<router-view></router-view>
	</div>
</template>

<script>
	import Header from './Header'
	import SidebarContent from './SidebarContent'
	export default {
		name: 'App',
		components: {Header, SidebarContent},
		data: () => ({
		}),
	};
</script>
