<template>
  <v-container
    fluid
    ref="container"
    class="py-0 align-start sidebar-container grey--text text--darken-2"
    :style="
       $vuetify.breakpoint.mobile ? 'height: 100vh; overflow: hidden;' : 'height: 100%; overflow: hidden;'
    "
  >
    <v-row>
      <v-col class="pa-0 mt-0">
        <div
          class="d-flex align-center justify-center text-center"
          style="background: linear-gradient(180deg, #066fe5 0%, #4baaef 100%) 0%
    0% no-repeat padding-box; opacity: 1"
        >
          <v-img
            :src="require('../../assets/imgs/logo-top.svg')"
            contain
            height="100"
            width="100"
            :class="'hidden-lg-and-up' "
          />
          <v-btn
            icon
            color="#707070"
            @click="$store.commit('setDrawerState', false)"
            :class="'hidden-lg-and-up white blue--text font-weight-bold ma-4'"
               >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
          <div class="d-flex align-center px-4 py-3" ref="topContainer">
            <v-avatar size="70">
              <v-img
                v-if="
                  $store.state.userData &&
                    $store.state.userData.info &&
                    $store.state.userData.info.avatar
                "
                :src="$store.state.userData.info.avatar"
                width="70"
                height="70"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                v-else
                style="width: 70px; height: 70px;line-height: 70px;"
                class="rounded-circle background-sosclick white--text headline textcolor-sosclick"
              >
                {{
                  $store.state.userData
                    ? $store.state.userData.name.match(/\b(\w)/g).join("")
                    : ""
                }}
              </span>
            </v-avatar>
            <div class="px-2">
              <p class="mb-0 subtitle-2 ">
                {{
                  $store.state.userData
                    ? $store.state.userData.name
                    : ""
                }}
              </p>
              <a
                class="pa-0 ma-0 textcolor-sosclick subtitle-2 font-weight-regular"
                @click="editProfile()"
                >Editar Perfil</a
              >
            </div>
        </div>
 
          </v-col>
        </v-row>
        <v-divider style="border-color: #707070;"></v-divider>
        <div
          class="d-flex flex-column "
          
    :style="
       $vuetify.breakpoint.mobile ? 'max-height: 35%; overflow-y: auto;' : 'max-height: 40%; overflow-y: auto;'
    "
        >
          <p
            class="mb-0 ml-3 textcolor-sosclick body-4 font-weight-medium py-3"
          >
            Dispositivos
          </p>
          <div
            v-if="
              $store.state.userData &&
                $store.state.userData.dispositivos.length
            "
            style="overflow-y: auto;"
          >
            <div
              class="d-flex align-center pa-0 mx-4 my-2"
              :key="index"
              v-for="(item, index) in $store.state.userData.dispositivos"
            >
              <v-icon
                class="mr-3"
                >
                mdi-cellphone
              </v-icon>
              <div class="px-2">
                <p class="mb-0 subtitle-2 ">
                  {{
                    item.device_name
                  }}
                </p>
                <p
                  class="pa-0 ma-0  subtitle-2 font-weight-regular"
 
                  >{{
                    item.device_model
                  }}</p
                >
              </div>
            </div>    
          </div>
          <div class="mb-0 text-center body-2 py-3" v-else>
            No hay dispositivos asociados a tu cuenta
          </div>
        </div>
        <v-divider style="border-color: #707070;"></v-divider>
        <div
          class="d-flex flex-column"
        :style="
            $vuetify.breakpoint.mobile ? 'max-height: 35%; overflow-y: auto;' : 'max-height: 40%; overflow-y: auto;'
          ">
          <p
            class="ml-3 textcolor-sosclick body-4 font-weight-medium my-3"
          >
            Smartbands
          </p>
          <div
            v-if="
              $store.state.userData &&
                $store.state.userData.smartbands.length
            "
            style="overflow-y: auto !important;border-top: 1px solid #707070 !important;"
          >
            <div
              class="d-flex align-center pa-0 mx-4 my-2"
              :key="index"
              v-for="(item, index) in $store.state.userData.smartbands"
            >
              <v-icon
                class="mr-3"
                >
                mdi-watch-variant
              </v-icon>
              <div class="px-2">
                <p class="mb-0 subtitle-2 ">
                  {{
                    item.name
                  }}
                </p>
              </div>
            </div> 
          </div>
          <div class="mb-0 text-center body-2 py-3" v-else>
            No hay smartbands asociados a tu cuenta
          </div>
        </div>
  </v-container>
</template>

<script>
import { getUser } from "../../helpers/api/user";
export default {
  name: "SidebarContent",
  components: {},
  data: () => ({
    height: 200,
  }),
  mounted() {
    this.getUserData();
    this.height =
      this.$refs.container.clientHeight -
      this.$refs.topContainer.clientHeight -
      1; //1 por el borde
  },
  methods: {
    editProfile() {
      this.$store.commit("setModalsStates", { editProfile: true });
      this.$store.commit("setDrawerState", false);
    },
    selectDevice(item) {
      this.$store.commit("setSelectedDevice", item);
      this.$store.commit("setDrawerState", false);
    },
    getUserData() {
      if (!this.$store.state.userData) {
        getUser();
      }
    },
  },
};
</script>
