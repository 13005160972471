<template>
  <v-container fluid style="background-color: #007BED;overflow-y: scroll;justify-content: center; height:100vh;">
        <div v-show="loader" style="display:flex;justify-content:center; align-items:center; flex-direction: column;height:100vh; width: 100vw; z-index:123123; position: absolute; top: 0;">
            <img
            :src="require('../../assets/logo-iduam.svg')"
            class="my-3"
            contain
            style="width: 300px;margin: 0 auto;"
          />
      <div class="loader"></div>
    </div>
    <div v-show="!loader">
    <div v-if="redirect && isMobile" style="margin:margin: 0 auto; display:flex; justify-content: center; align-items:center; flex-direction: column;height: 100vh;">
          <img
            :src="require('../../assets/logo-iduam.svg')"
            class="my-3"
            contain
            style="width: 300px;margin: 0 auto;"
          />
          <p style="color: #FFF; font-size: 20px;">Redireccionando en {{count}}</p> <div class="loader"></div>
    </div>

    <div v-else>
    <v-snackbar
      v-model="showMessages"
      vertical
      top
      right
    >
      <p class="mb-0">Hay errores en el formulario:</p>
      <p
        v-for="(msg, index) in messages"
        :key="index"
        class="mb-0 d-block"
        >{{(index + 1) + ': ' + msg}}</p>
    </v-snackbar>
    <v-row class="text-center">
      <v-col cols="12">
        <div class="mx-auto" style="max-width: 500px;">
          <v-img
            :src="require('../../assets/logo-iduam.svg')"
            class="my-3"
            contain
            height="200"
          />
          <div v-if="registerOK">
            <v-img src="../../assets/imgs/shield.svg" max-height="200px" contain></v-img>
            <p class="white--text my-5 display-1">Registro Completo</p>
            <p class="white--text mb-0">Estás sólo a un paso de estar protegido.</p>
            <p class="white--text">Descarga la aplicación desde Play Store o App Store.</p>
            <v-btn
              color="white"
              rounded
              outlined
              large
              @click="loginUser"
              style="width: 200px;"
              class="mx-2 my-5"
              :class="$vuetify.breakpoint.mobile ? 'mb-3':''"
              :block="$vuetify.breakpoint.mobile"
            >
              <p class="body-2 mb-0" >Ir al panel de usuario</p>
            </v-btn>
            <v-row class="text-center align-center">
              <v-col cols="6">
                <a href="https://play.google.com/store/apps/details?id=com.naturalphone.sosclickiduam" target="_blank">
                  <v-img
                    style="max-width: 100%"
                    src="../../assets/imgs/google-play-badge.png" contain></v-img>
                </a>
              </v-col>
              <v-col cols="6">
                <a href="https://apps.apple.com/us/app/iduam/id1529934806" target="_blank">
                  <v-img
                    style="max-width: 100%"
                    src="../../assets/imgs/Download_on_the_App_Store_Badge_ES_RGB_blk_100217.svg" contain></v-img>
                </a>
              </v-col>
            </v-row>
          </div>
          <v-form
            v-else
            ref="form"
            class="custom-form-registro"
            v-model="valid"
          >
            <div class="text-center">
              <p class="white--text mb-0">Regístrate y genera tu red de seguridad</p>
              <p class="white--text" v-if="userInvite">
                <span v-if="codeType === 1">Vas a ser agregado a la red de seguridad de </span>
                <span v-if="codeType === 2">Estás usando el código de referido de </span>
                <b>{{userInvite.name}}</b>
              </p>
              <p class="subtitle white--text" style="font-weight: 600;" v-if="security_code_error">* {{security_code_error}}</p>
              <p class="subtitle white--text" v-if="userSponsor">
                Al registrarte, serás asignado automáticamente al patrocinador <span style="font-weight: 800;">{{userSponsor.name}}</span> y tendrás acceso a todas las funcionalidades de Iduam del plan asociado sin costo adicional.
              </p>
              <v-text-field
                v-model="userInfo.name"
                :rules="required"
                label="Nombre"
                required
                dark
              ></v-text-field>
<!--               <v-text-field
                v-model="userInfo.rut"
                :rules="rut"
                label="RUT"
                required
                @change="(rut) => {formatRut(rut)}"
                dark
              ></v-text-field> -->
              <v-text-field
                v-model="userInfo.email"
                :rules="isEmailValid"
                label="Email"
                required
                @change="(email) => {isEmail(email)}"
                dark
              ></v-text-field>
<!--               <v-select
                v-model="userInfo.pais"
                :items="paises"
                :rules="required"
                item-text="name"
                item-value="id"
                hint="Se muestran sólo los países en los que Iduam está disponible"
                persistent-hint
                label="Países"
                dark
                @change="getRegion"
              ></v-select>
              <v-select
                v-model="userInfo.region"
                :items="regiones"
                :rules="required"
                item-text="region"
                item-value="id"
                hint="Se muestran sólo las regiones en las que Iduam está disponible"
                persistent-hint
                label="Región"
                dark
                @change="getComunasActivas"
              ></v-select>
              <v-select
                v-if="comunas.length > 0"
                v-model="userInfo.comuna"
                :items="comunas"
                :rules="required"
                item-text="comuna"
                item-value="id"
                hint="Se muestran sólo las comunas en las que Iduam está disponible"
                persistent-hint
                label="Comuna"
                dark
              ></v-select>
              <v-text-field
                v-model="userInfo.direccion"
                :rules="required"
                label="Dirección"
                required
                dark
              ></v-text-field>
              <v-text-field
                v-model="userInfo.telefono"
                :rules="requiredPhone"
                label="Teléfono"
                :counter="11"
                maxlength="11"
                required
                dark
              ></v-text-field>
              <v-select
                v-model="userInfo.sexo"
                :items="items"
                :rules="required"
                label="Género"
                dark
              ></v-select>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="userInfo.birthday"
                    :rules="required"
                    label="Fecha Nacimiento"
                    readonly
                    v-bind="attrs"
                    prepend-inner-icon="mdi-calendar"
                    v-on="on"
                    dark
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  locale="es-ES"
                  dark
                  v-model="userInfo.birthday"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="(date) => $refs.menu.save(date)"
                ></v-date-picker>
              </v-menu> -->
              <v-text-field
                v-model="userInfo.password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="required"
                :type="showPass ? 'text' : 'password'"
                name="input-10-1"
                label="Contraseña"
                hint="Mínimo 8 caracteres"
                dark
                @click:append="showPass = !showPass"
              ></v-text-field>
              <v-btn class="mt-4" depressed dark block @click="() => registro()">Registrar</v-btn>
              <button style="" class="google-btn mt-4" type="button"  @click="() => registroProvider('google')">
                <img  style="background: #F2F3F5;width: 30px;" src="../../assets/google-logo.png" alt="Google Logo">
                <span>Inicia Sesión con Google</span>
                <span></span>
              </button>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
        </div>
    </div>
  </v-container>
</template>

<script>
  import { validate, format } from 'rut.js'
  import {paisesActivos, regionesActivas, comunasActivas, getUserByCode, signUpByCode, getUserWithSponsorCode, login, loginWithGoogleToken} from '../../helpers/api/user'

  export default {
    name: 'Register',
    data: () => ({
      count: 3,
      menu: false,
      showMessages: false,
      messages: null,
      showPass: false,
      registerOK: false,
      paises: [],
      regiones: [],
      comunas: [],
      userInfo: {
        name: '',
        email: '',
/*         direccion: '',
        telefono: '',
        rut: '',
        sexo: 2,
        birthday: new Date().toISOString().substr(0, 10), */
        password: '',
/*         comuna: null */
      },
      valid: false,
      required: [
        v => !!v || 'Este campo es obligatorio'
      ],
      rut: [
        v => (!!v || 'Este campo es obligatorio') && (validate(v) || 'Rut no válido')
      ],
      requiredPhone: [
        v => !!v || 'Este campo es obligatorio',
        v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números'
      ],
      isEmailValid: [
        //eslint-disable-next-line
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Formato incorrecto'
      ],
      items: [{text: 'Femenino', value: 2}, {text: 'Masculino', value: 1}],
      userInvite: null,
      codeType: 1,
      device: null,
      redirect: false,
      userSponsor: null,
      isMobile: null,
      security_code_error: '',
      sponsor_code_error: '',
      access_token: null,
      loader: false,
      code_error: false
    }),
        computed: {
      params() {
          const security_code = this.$route.query.security_code
          const share_code = this.$route.query.share_code
          const sponsor_code = this.$route.query.sponsor_code

          let params = ''
          if (security_code) {
            params = `&security_code=${security_code}`
          }
          if (share_code) {
            params = `&share_code=${share_code}`
          }
          if (sponsor_code) {
            params = `&sponsor_code=${sponsor_code}`
          }
          return params
      },
      paramsPlaystore() {
          const security_code = this.$route.query.security_code
          const share_code = this.$route.query.share_code
          const sponsor_code = this.$route.query.sponsor_code

        let paramsPlaystore = ''
          if (security_code) {
            paramsPlaystore = `security_code%3D${security_code}`
          }
          if (share_code) {
            paramsPlaystore = `share_code%3D${share_code}`
          }
          if (sponsor_code) {
            paramsPlaystore = `sponsor_code%3D${sponsor_code}`
          }
          return paramsPlaystore
      }
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    mounted(){
      this.$store.commit('setShowNavbar', false)
      this.access_token = this.$route.query.social_access_token
      const security_code = this.$route.query.security_code
      const sponsor_code = this.$route.query.sponsor_code
      
      this.getUserWithCode(security_code)
      this.getSponsorCode(sponsor_code)
      this.getPaises()

    /* redirect */
    this.isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/);
    if (this.isMobile) {

          const security_code = this.$route.query.security_code
          const share_code = this.$route.query.share_code
          const sponsor_code = this.$route.query.sponsor_code

          let params 
          if (security_code) {
            params = `?security_code=${security_code}`
          }
          if (share_code) {
            params = `?share_code=${share_code}`
          }
          if (sponsor_code) {
            params = `?sponsor_code=${sponsor_code}`
          }
          params = this.code_error ? '' : params
          if (navigator.userAgent.includes('Android')) { //abre app sólo en android

          setTimeout(() => {
            window.location = `iduamapp://register${params}`
          }, 500);
            }

            if (this.access_token) {
              this.loader = true
              loginWithGoogleToken(this.access_token).then(res => {
                console.log(res);
                this.loader = false
              this.registerOK = true
              this.redirect = true

                /* redirect */
          const isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/);
          if (isMobile) {
            this.loader = false
            setInterval(() => {
              if (this.count > 0) {
                this.count = this.count - 1
            }
          }, 1000);
              this.$store.commit('setShowNavbar', false)

            setTimeout(() => {
            if (navigator.userAgent.includes('Android')) {
              window.location =`https://play.google.com/store/apps/details?id=com.naturalphone.sosclickiduam`
/*               window.location =`market://details?id=com.naturalphone.sosclickiduam` */
            } else {
              window.location = `https://apps.apple.com/us/app/iduam/id1529934806`;
            }
          }, 3000);
            if (isMobile.includes('Android')) { //abre app sólo en android
                      const security_code = this.$route.query.security_code
          const share_code = this.$route.query.share_code
          const sponsor_code = this.$route.query.sponsor_code

          let params 
          if (security_code) {
            params = `&security_code=${security_code}`
          }
          if (share_code) {
            params = `&share_code=${share_code}`
          }
          if (sponsor_code) {
            params = `&sponsor_code=${sponsor_code}`
          }
          params = this.code_error ? '' : params
          setTimeout(() => {
            
            window.location = `iduamapp://register${params}`
          }, 500);
              }
              }
              }).catch(e => {
                console.log(e);
              })
            }
        } else {
          if (this.access_token) {
              this.loader = true

            loginWithGoogleToken(this.access_token).then(res => {
              console.log(res);
                this.$router.push({name: 'Home'})
              }).catch(e => { 
                console.log(e);
              })
          }
        }
    },
    methods: {
      formatRut(rut) {
        this.userInfo.rut = format(rut)
      },
      isEmail(email){
        //eslint-disable-next-line
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
      },
      async getUserWithCode(code){
        if(code){
          const data = { code }
          const user = await getUserByCode(data)
          this.userInvite = user.user
        }
      },
      async getSponsorCode(code){
          if(code){
          const data = {unique_id: code }
          const user = await getUserWithSponsorCode(data)
          this.userSponsor = user.sponsor
          this.code_error = false
        }

      },
      async getPaises(){
        const paises = await paisesActivos()
        this.paises = paises.paises
      },
      async getRegion(pais_id){
        const regiones = await regionesActivas({pais_id})
        this.regiones = regiones.regiones
      },
      async getComunasActivas(region_id){
        const comunas = await comunasActivas({region_id})
        this.comunas = comunas.comunas
      },
      async registro(){
        if(this.$refs.form.validate()){
          const self = this
          this.userInfo.code = this.$route.query.code
          this.userInfo.sharecode = this.$route.query.sharecode
          const data = this.userInfo
          signUpByCode(data)
            .then(async function () {
              self.registerOK = true
            })
            .catch(function (error) {
              self.messages = Object.values(error)
              self.showMessages = true
              console.log(self.messages)
            });
        }
      },
      loginUser () {
        const self = this
        login(this.userInfo.email, this.userInfo.password)
          .then(function () {
            self.$router.push({name: 'Home'})
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      registroProvider(provider) { 
        console.log(provider);
        window.location.href = `https://devapis.anubys.cl:8004/social-oauth?provider=${provider}${this.params}`;
      }
    }
  }
</script>
<style type="text/css" media="screen">
  .v-application .custom-form-registro .error--text {
    color: white !important;
    caret-color: #f2f2f2 !important;
  }

  .google-btn {
  display: flex;
  justify-content: space-between;
  background: #f2f3f5;
  max-width: 500px;
  margin: 0 auto;
  align-items: center;
  padding: 4px 12px;
  border-radius: 6px;
  border: #0000 solid 1px;
}

@media (max-width: 450px) {
  .google-btn {
    justify-content: center;
    gap: 8px;
    width: 100%;
  }
}

.google-btn img {
  background: #f2f3f5;
}

.loader {
  height: 15px;
  aspect-ratio: 5;
  -webkit-mask: linear-gradient(90deg#007BED ,#FFF 20% 80%,#007BED);
  background: radial-gradient(closest-side at 37.5% 50%,#FFF 94%,#007BED) 0/calc(80%/3) 100%;
  animation: l48 .75s infinite linear;
}
@keyframes l48 {
  100% {background-position: 36.36%}
}
</style>