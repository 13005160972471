<template>
	<v-layout justify-center align-center style="background-color: #007BED;height: 100%;">
    <v-snackbar
      v-model="showMessage"
      vertical
      bottom
      center
      :color="messageColor"
    >
      <p class="mb-0">{{message}}</p>
    </v-snackbar>
		<v-container>
			<v-row>
				<v-col cols="12">
					<h3 class="text-center white--text">Ingresa a tu cuenta</h3>
					<v-layout flex-column flex-md-row justify-center align-center style="width: 100%">
						<v-img
							max-height="300"
							max-width="300"
							class="mr-4"
							:src="require('../../assets/logo-iduam.svg')"
						></v-img>
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
							class="d-flex flex-column flex-grow-1"
							style="max-width: 400px;width: 100%;"
						>
							<v-text-field
								v-model="email"
								:rules="emailRules"
								label="Email"
								required
								dark
								class="custom-placeholer-color"
							></v-text-field>
							<v-text-field
								v-model="userPassword"
								:rules="passwordRules"
								label="Contraseña"
								dark
								required
								:append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="() => (passType = !passType)"
								:type="passType ? 'password' : 'text'"
							></v-text-field>
							<v-btn
								:disabled="!valid"
								color="success"
								class="mr-4"
								@click="loginUser"
							>
								Ingresar
							</v-btn>
							<button
                class="google-btn mt-4"
                type="button"
                @click="() => registroProvider('google')"
              >
                <img
                  style="background: #F2F3F5;width: 30px;"
                  src="../../assets/google-logo.png"
                  alt="Google Logo"
                />
                <span>Inicia Sesión con Google</span>
                <span></span>
              </button>
						</v-form>
					</v-layout>
				</v-col>
			</v-row>
		</v-container>
	</v-layout>
</template>
<script>
	import {login, loginWithGoogleToken} from '../../helpers/api/user'

	export default {
		data: () => ({
			valid: true,
			showMessage: false,
			messageColor: 'error',
			message: '',
			email: '',
			emailRules: [
				v => !!v || 'Email es obligatorio',
				v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
			],
			userPassword: '',
			passwordRules: [
				v => !!v || 'Contraseña es requerida'
			],
			passType: true
		}),
		mounted(){
			console.log(this.$router.currentRoute)

			const access_token = this.$route.query.social_access_token;
			console.log(access_token);

			if (access_token) {
				loginWithGoogleToken(this.access_token).then((res) => {
					console.log(res);
					this.$router.push({ name: "Home" });
			}).catch((e) => {
				console.log(e);
			});
			}
		},
		methods: {
			validate () {
				this.$refs.form.validate()
			},
			reset () {
				this.$refs.form.reset()
			},
			resetValidation () {
				this.$refs.form.resetValidation()
			},
			loginUser () {
				const self = this
				login(this.email, this.userPassword).then(function (resp) {
			if(resp.code === 200){
				console.log(0);
				if(self.$route.query && self.$route.query.redirect_to){
					self.$router.push({ path: self.$route.query.redirect_to })
					console.log('1');
				}
				else{
					self.$router.push({name: 'Home'})
					console.log('2');
				}
			}
					})
					.catch(function (error) {
						self.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
						if(error.response && error.response.status == 401){
							self.message = 'Email o contraseña incorrectos. Por favor verifica los datos ingresados.'
						}
						self.messageColor = 'warning'
						self.showMessage = true
						console.log(error.response)
					})
			},
		registroProvider(provider) {
			window.location.href = `https://devapis.anubys.cl:8004/social-oauth?provider=${provider}`;
			console.log(`https://api.sosclick.cl/social-oauth?provider=${provider}`);
		},
		},
	}
</script>
<style>
	.custom-placeholer-color input::placeholder {
		color: red!important;
		opacity: 1;
	}

	.custom-label-color .v-label {
		color: red;
		opacity: 1;
	}
	.google-btn {
  display: flex;
  justify-content: space-between;
  background: #f2f3f5;
  max-width: 500px;
  margin: 0 auto;
  align-items: center;
  padding: 4px 12px;
  border-radius: 6px;
  border: #0000 solid 1px;
}

@media (max-width: 450px) {
  .google-btn {
    display: block;
    width: 100%;
  }
}

.google-btn img {
  background: #f2f3f5;
}
</style>