<template>
  <v-container fill-height align-start>
    <v-row align="start" justify="center" class="text-center">
      <v-col cols="12" sm="4"> </v-col>
    </v-row>
    <v-row justify="center" class="text-center d-flex">
      <v-col cols="12" sm="6" class="d-flex flex-grow-1">
        <v-card class="mt-0 px-4 py-10">
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            type="paragraph,paragraph"
          ></v-skeleton-loader>
          <div v-else>
            <p class="textcolor-sosclick">Tu código de referidos</p>
            <p
              class="body-2 text-left mb-0 d-flex justify-space-between px-2 py-1"
            >
              <b>Código referido:</b>
              {{ $store.state.userData.info.codigo_invitacion }}
            </p>
            <p
              class="body-2 text-left mb-0 d-flex justify-space-between px-2 py-1"
            >
              <b>Link directo a registro: </b>
            </p>
            <p
              class="body-2 text-left my-0 d-flex justify-space-between align-center px-2"
            >
              <v-text-field
                disabled
                :value="
                  'https://usuarios.sosclick.cl/register?sharecode=' +
                    $store.state.userData.info.codigo_invitacion
                "
              ></v-text-field>
              <input
                type="hidden"
                ref="urlRegister"
                :value="
                  'https://usuarios.sosclick.cl/register?sharecode=' +
                    $store.state.userData.info.codigo_invitacion
                "
              />
              <v-btn
                color="#007BED"
                depressed
                dark
                @click="copyUrlRegiter()"
                class="mx-2"
              >
                <p class="body-2 mb-0">Copiar</p>
              </v-btn>
            </p>
            <p class="body-2 mb-0 mt-4" style="color:#707070">
              Comparte este link con la mayor cantidad de gente posible,
              mientras más usuarios se registren con tu código más ganancias
              generarás.
            </p>
            <p class="caption mb-0" style="color:#707070">
              Ganarás comisión por cada compra o renovación de plan realizada
              por los usuarios que hayas referido.
            </p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex flex-grow-1">
        <v-card class="mt-0 px-4 py-10">
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            type="paragraph,paragraph"
          ></v-skeleton-loader>
          <div v-else>
            <div class="d-flex align-start">
              <p class="textcolor-sosclick mb-0 flex-grow-1">
                Resumen general del periodo
              </p>
              <v-select
                v-model="month"
                :items="months"
                item-text="month"
                item-value="id"
                label="Mes"
                dense
                class=" flex-grow-0"
                style="max-width: 40%"
                @change="getResumenVentas"
              ></v-select>
            </div>
            <p
              class="body-2 text-left mb-0 d-flex justify-space-between px-2 py-1"
            >
              <b>Cantidad de referidos:</b>
              {{ resumen.user_referred_id.length }}
            </p>
            <p
              class="body-2 text-left mb-0 d-flex justify-space-between px-2 py-1"
            >
              <b>Pagos por referidos (#):</b> {{ resumen.user_payments.length }}
            </p>
            <p
              class="body-2 text-left mb-0 d-flex justify-space-between px-2 py-1"
            >
              <b>Pagos por referidos ($):</b> ${{
                resumen.amount_payments | toThousandFilter
              }}
            </p>
            <p
              class="headline textcolor-sosclick text-left mb-0 d-flex justify-space-between px-2 py-1 mt-2"
              style="border-top: 1px solid #c1c1c1"
            >
              <b>Tu comisión:</b> ${{
                Math.round((resumen.amount_payments * referred_comision) / 100)
                  | toThousandFilter
              }}
            </p>
            <p
              class="caption text-left mb-0 d-flex justify-space-between px-2 py-1"
              style="color:#707070"
            >
              Este es el monto que te pagaremos al finalizar el periodo
            </p>
            <p class="caption mb-0 mt-4" style="color:#707070">
              * A excepción de la cantidad referidos, esta sección muestra los
              datos para el periodo actual de facturación
            </p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex flex-grow-1">
        <v-card class="mt-0 px-4 py-10" style="width: 100%;max-height: 400px;">
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            type="paragraph,paragraph"
          ></v-skeleton-loader>
          <div v-else>
            <p class="textcolor-sosclick mb-0">Cantidad de referidos</p>
            <p class="caption" style="color:#707070">
              Usuarios nuevos registrados con tu código de referido
            </p>
            <div class="d-flex" style="max-height: 260px;position: relative;">
              <LineChart
                ref="referidos"
                style="width: 100%;"
                :chart-data="lineChartData"
              />
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex flex-grow-1">
        <v-card class="mt-0 px-4 py-10" style="width: 100%;max-height: 400px;">
          <v-skeleton-loader
            v-if="loading"
            class="mx-auto"
            type="paragraph,paragraph"
          ></v-skeleton-loader>
          <div v-else>
            <p class="textcolor-sosclick mb-0">Pagos de referidos</p>
            <p class="caption" style="color:#707070">
              Comparación de los pagos realizados por tus referidos entre este
              periodo y el pasado
            </p>
            <div class="d-flex" style="max-height: 260px;position: relative;">
              <DoughnutChart
                ref="payments_amount"
                style="width: 100%;"
                :chart-data="chartData"
              />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.status"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.status = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DoughnutChart from "../Graphs/Doughnut";
import LineChart from "../Graphs/Line";
import { getSalesResumen } from "../../helpers/api/sales";
export default {
  name: "SalesResumen",
  components: { DoughnutChart, LineChart },
  filters: {
    toThousandFilter(num) {
      return (+num || 0)
        .toString()
        .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, "."));
    },
  },
  computed: {
    ...mapGetters(["salesData"]),
  },
  watch: {
    salesData: function(data) {
      this.referred_comision = data.referred_comision;
    },
  },
  data: () => ({
    loading: true,
    resumen: null,
    snackbar: {
      status: false,
      text: "",
      color: "success",
      timeout: 4000,
    },
    months: [
      { id: "1 January", month: "Enero" },
      { id: "1 February", month: "Febrero" },
      { id: "1 March", month: "Marzo" },
      { id: "1 April", month: "Abril" },
      { id: "1 May", month: "Mayo" },
      { id: "1 June", month: "Junio" },
      { id: "1 July", month: "Julio" },
      { id: "1 August", month: "Agosto" },
      { id: "1 September", month: "Septiembre" },
      { id: "1 October", month: "Octubre" },
      { id: "1 November", month: "Noviembre" },
      { id: "1 December", month: "Diciembre" },
    ],
    month: null,
    referred_comision: 0,
    lineChartData: {
      labels: [],
      datasets: [
        {
          data: [],
          borderColor: "#007BED",
          pointRadius: 5,
          fill: false,
          label: "Usuarios nuevos",
        },
      ],
    },
    chartData: {
      labels: ["Mes Pasado", "Este Mes"],
      datasets: [
        {
          backgroundColor: ["grey", "#007BED"],
          data: [0, 0],
        },
      ],
    },
  }),
  mounted() {
    this.getResumenVentas();
    this.referred_comision = this.$store.state.salesData.referred_comision;
  },
  methods: {
    setCurrentMonth(monthINT) {
      if (!this.month) {
        this.month = this.months[monthINT - 1].id;
      }
    },
    copyUrlRegiter() {
      let textToCopy = this.$refs.urlRegister;
      textToCopy.setAttribute("type", "text");
      textToCopy.select();
      document.execCommand("copy");
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
      this.snackbar = {
        status: true,
        text:
          "Url copiada al portapapeles. Compártela en tus redes o donde quieras.",
        color: "success",
        timeout: 6000,
      };
    },
    async getResumenVentas() {
      const data = { month: this.month };
      const resumen = await getSalesResumen(data);
      this.resumen = resumen;
      this.setCurrentMonth(this.resumen.current_month);
      this.chartData = {
        labels: ["Mes Pasado", "Este Mes"],
        datasets: [
          {
            backgroundColor: ["grey", "#007BED"],
            data: [
              this.resumen.amount_previous_payments,
              this.resumen.amount_payments,
            ],
          },
        ],
      };
      this.lineChartData = {
        labels: this.resumen.array_months,
        datasets: [
          {
            data: this.resumen.referred_by_month,
            borderColor: "#007BED",
            pointRadius: 5,
            fill: false,
            label: "Usuarios nuevos",
          },
        ],
      };
      this.loading = false;
    },
  },
};
</script>
