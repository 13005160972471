<template>
  <v-container
    fill-height
    align-start
    style="max-height: 100%;"
    ref="containerInfo"
  >
    <v-row ref="headerInfo">
      <v-col cols="12" sm="6">
        <h4 class="d-flex align-center">
          <v-img
            :src="require('../../assets/imgs/user.svg')"
            width="70"
            height="70"
            max-width="70"
            max-height="70"
          ></v-img>
          <span class="headline textcolor-sosclick" style="color: #707070;"
            >Tracking dispositivo</span
          >
        </h4>
      </v-col>
      <v-col cols="12" sm="6">
        <v-alert
          :color="infoTexts.color"
          dark
          dense
          icon="mdi-information"
          border="left"
          prominent
          class="custom-alert-info mb-0"
        >
          <h5>{{ infoTexts.title }}</h5>
          <p class="caption mb-0">{{ infoTexts.text }}</p>
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="text-center fill-height" :style="{ height: height + 'px' }">
      <v-col cols="12" sm="5" style="max-height: 100%;">
        <v-row>
          <v-col cols="12" class="pa-0 mb-3">
            <v-btn
              color="#007BED"
              depressed
              rounded
              outlined
              :disabled="!online"
              dark
              small
              @click="showCurrentRoute = !showCurrentRoute"
              style="width: 220px;"
              class="mx-2 custom-button-disabled-sos-outlined"
            >
              <v-icon class="mr-2">
                mdi-map-marker-distance
              </v-icon>
              <p class="button mb-0">
                {{
                  showCurrentRoute ? "Ocultar Ruta Activa" : "Ver Ruta Activa"
                }}
              </p>
            </v-btn>
            <v-btn
              color="#007BED"
              depressed
              rounded
              :loading="toogleTracking"
              dark
              small
              @click="online ? deactivateTracking() : activateTracking()"
              style="width: 220px;"
              class="mx-2"
            >
              <v-icon class="mr-2">
                mdi-map-marker-outline
              </v-icon>
              <p class="button mb-0">
                {{ online ? "Desactivar Tracking" : "Activar Tracking" }}
              </p>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          dense
          locale="es-ES"
          :loading="loading"
          loading-text="Cargando Datos"
          :headers="headers"
          :items="trackings"
          :item-class="getItemsClass"
          :server-items-length="totalItems"
          @pagination="paginate"
          class="custom-table-atencion"
          :options="tableOptions"
          mobile-breakpoint="0"
        >
          <template v-slot:item.start="{ item }">
            {{ item.created_at | date }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  tile
                  small
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  @click="showRoute(item)"
                >
                  <v-icon color="#707070">mdi-map-marker-path</v-icon>
                  <v-icon
                    large
                    class="rotate-icon-slash"
                    v-show="selectedRouteID === item.id"
                    color="red"
                    style="position: absolute; top: 0; bottom: 0; right: 0; left: 0;"
                    >mdi-slash-forward</v-icon
                  >
                </v-btn>
              </template>
              <span>{{
                selectedRouteID === item.id ? "Ocultar ruta" : "Ver ruta"
              }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" sm="7" style="max-height: 100%;">
        <GmapMap
          ref="map"
          :center="centerMap"
          :zoom="mapZoom"
          :options="{
            maxZoom: 16,
            streetViewControl: true,
            mapTypeControl: true,
            zoomControl: true,
          }"
          class="map-container"
          style="min-height: 300px"
        >
          <div v-if="showCurrentRoute && currentRoute.length > 0">
            <GmapMarker
              :key="'trakingActualB'"
              :ref="'trakingActualB'"
              :position="currentRoute[currentRoute.length - 1]"
              :clickable="true"
              customInfo="Work"
              :icon="{
                url: require('../../assets/imgs/marker4.png'),
                scaledSize: { width: 32, height: 44 },
              }"
              :zIndex="3"
            />
            <GmapMarker
              :key="'trakingActualA'"
              :ref="'trakingActualA'"
              :position="currentRoute[0]"
              :clickable="true"
              customInfo="Work"
              :icon="{
                url: require('../../assets/imgs/marker2.png'),
                scaledSize: { width: 32, height: 44 },
              }"
              :zIndex="2"
            />
            <GmapPolyline
              :options="{
                strokeWeight: 5,
                strokeColor: '#007BED',
                strokeOpacity: 0.4,
              }"
              :path="currentRoute"
            />
          </div>
          <div v-if="deviceRoute.length > 0">
            <GmapMarker
              :key="'trackingActualB'"
              :ref="'trackingActualB'"
              :position="deviceRoute[deviceRoute.length - 1]"
              :clickable="true"
              customInfo="Work"
              :icon="{
                url: require('../../assets/imgs/marker4.png'),
                scaledSize: { width: 32, height: 44 },
              }"
              :zIndex="3"
            />
            <GmapMarker
              :key="'trackingActualA'"
              :ref="'trackingActualA'"
              :position="deviceRoute[0]"
              :clickable="true"
              customInfo="Work"
              :icon="{
                url: require('../../assets/imgs/marker2.png'),
                scaledSize: { width: 32, height: 44 },
              }"
              :zIndex="2"
            />
            <GmapPolyline
              :options="{
                strokeWeight: 5,
                strokeColor: '#007BED',
                strokeOpacity: 0.4,
              }"
              :path="deviceRoute"
            />
          </div>
        </GmapMap>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.status"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { gmapApi } from "vue2-google-maps";
import {
  getTrackingByDevice,
  activateTrackingRemote,
} from "../../helpers/api/tracking";
import moment from "moment";
import * as firebase from "firebase/app";
import "firebase/database";

export default {
  name: "Tracking",
  filters: {
    date: function(value) {
      if (!value) return "No registra";
      return moment(value).format("DD-MM-YYYY HH:mm");
    },
  },
  computed: {
    ...mapGetters(["selectedDeviceData"]),
    google: gmapApi,
  },
  data() {
    return {
      centerMap: {
        lat: -33.4373063,
        lng: -70.634297,
      },
      mapZoom: 12,
      tableOptions: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        {
          text: "Fecha",
          value: "created_at",
          align: "center",
          sortable: false,
        },
        {
          text: "Visualizar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      snackbar: {
        status: false,
        text: "",
        color: "success",
        timeout: 4000,
      },
      infoTexts: {
        color: "warning",
        title: "Información",
        text:
          "Selecciona una ruta o activa el tracking para ver en tiempo real la posición del dispositivo.",
      },
      trackings: [],
      totalItems: 0,
      loading: false,
      height: 200,
      currentRoute: [],
      deviceRoute: [],
      trackingInfo: null,
      online: false,
      toogleTracking: false,
      showCurrentRoute: false,
      selectedRouteID: null,
    };
  },
  watch: {
    selectedDeviceData: function() {
      this.getTrackings(this.tableOptions);
      if (this.trackingInfo) {
        this.removeListenners();
      }
      this.trackingInfo = this.$store.state.selectedDevice;
      this.createListenners();
    },
  },
  mounted() {
    this.trackingInfo = this.$store.state.selectedDevice;
    this.height =
      this.$refs.containerInfo.clientHeight -
      this.$refs.headerInfo.clientHeight -
      12;
    this.getTrackings(this.tableOptions);
  },
  beforeDestroy() {
    this.removeListenners();
  },
  methods: {
    getItemsClass(item) {
      let className = "custom-items-table";
      if (this.selectedRouteID === item.id) {
        className += " selected-row-custom";
      }
      return className;
    },
    paginate(data) {
      this.getTrackings(data);
    },
    showRoute(item) {
      if (this.selectedRouteID === item.id) {
        this.selectedRouteID = null;
        this.deviceRoute = [];
      } else {
        this.selectedRouteID = item.id;
        this.deviceRoute = item.route;
        this.fitMarkers(this.deviceRoute);
      }
    },
    fitMarkers(markers) {
      let bounds = new this.google.maps.LatLngBounds();
      markers.map((item) => {
        bounds.extend(new this.google.maps.LatLng(item.lat, item.lng));
      });
      this.$refs.map.fitBounds(bounds, 0);
    },
    async getTrackings(query) {
      if (this.$store.state.selectedDevice) {
        this.loading = true;
        const data = {
          device_id: this.$store.state.selectedDevice.device_id,
          limit: query.itemsPerPage,
          page: query.page,
        };
        const trackings = await getTrackingByDevice(data);
        this.trackings = trackings.tracking;
        this.totalItems = trackings.total_trackings;
        this.loading = false;
      }
    },
    createListenners() {
      const self = this;
      const cuadrante = this.$store.state.userData.info.cuadrante_id;
      const id_usuario = this.$store.state.userData.id;
      const path =
        "SOSclick/tracking/" +
        cuadrante +
        "/" +
        id_usuario +
        "/" +
        this.trackingInfo.device_id;
      firebase
        .database(this.$store.state.trackingDB)
        .ref(path)
        .on("child_added", function(snapshot) {
          let datos = snapshot.val();
          if (snapshot.key === "route") {
            self.online = true;
            self.currentRoute = datos;
            self.centerMap = {
              lat: self.currentRoute[self.currentRoute.length - 1].lat,
              lng: self.currentRoute[self.currentRoute.length - 1].lng,
            };
            self.infoTexts = {
              color: "success",
              title: "Tracking activo para dispositivo",
              text:
                "Hay un tracking actualmente activo, puedes visualizarlo presionando en el botón de la columna izquierda.",
            };
          }
        });
      firebase
        .database(this.$store.state.trackingDB)
        .ref(path)
        .on("child_changed", function(snapshot) {
          let datos = snapshot.val();
          if (snapshot.key === "route") {
            self.online = true;
            self.currentRoute = datos;
            self.centerMap = {
              lat: self.currentRoute[self.currentRoute.length - 1].lat,
              lng: self.currentRoute[self.currentRoute.length - 1].lng,
            };
            self.infoTexts = {
              color: "success",
              title: "Tracking activo para dispositivo",
              text:
                "Hay un tracking actualmente activo, puedes visualizarlo presionando en el botón de la columna izquierda.",
            };
          }
        });
    },
    removeListenners() {
      const cuadrante = this.$store.state.userData.info.cuadrante_id;
      const id_usuario = this.$store.state.userData.id;
      const path =
        "SOSclick/tracking/" +
        cuadrante +
        "/" +
        id_usuario +
        "/" +
        this.trackingInfo.device_id;

      firebase
        .database(this.$store.state.trackingDB)
        .ref(path)
        .off("child_added");
      firebase
        .database(this.$store.state.trackingDB)
        .ref(path)
        .off("child_changed");
      this.trackingInfo = null;
    },
    activateTracking() {
      const self = this;
      const cuadrante = this.$store.state.userData.info.cuadrante_id;
      const id_usuario = this.$store.state.userData.id;
      const data = {
        device_id: this.trackingInfo.device_id,
        trackingStatus: 1,
      };
      this.toogleTracking = true;
      this.infoTexts = {
        color: "info",
        title: "Activando tracking",
        text: "Por favor espera mientras activamos el tracking.",
      };
      const path =
        "SOSclick/tracking/" +
        cuadrante +
        "/" +
        id_usuario +
        "/" +
        self.trackingInfo.device_id;
      let listener = firebase
        .database(self.$store.state.trackingDB)
        .ref(path)
        .on("child_added", function(datos) {
          if (datos.val()) {
            self.showCurrentRoute = true;
            self.snackbar = {
              status: true,
              text:
                "El tracking ha sido activado en el dispositivo, ahora podrás visualizar su posición en tiempo real.",
              color: "success",
              timeout: 6000,
            };
            self.infoTexts = {
              color: "success",
              title: "Tracking activo para dispositivo",
              text:
                "Hay un tracking actualmente activo, puedes visualizarlo presionando en el botón de la columna izquierda.",
            };
            self.online = true;
          }
          self.toogleTracking = false;
          firebase
            .database(self.$store.state.trackingDB)
            .ref(path)
            .off("child_added", listener);
          setTimeout(function() {
            self.toogleTracking = false;
          }, 4000);
        });
      activateTrackingRemote(data)
        .then(function() {
          self.infoTexts = {
            color: "info",
            title: "Activando tracking",
            text:
              "Por favor espera mientras activamos el tracking. Esperando notificación de activación.",
          };
        })
        .catch(function() {
          self.snackbar = {
            status: true,
            text:
              "Hubo un error de red al enviar la solicitud, por favor inténtalo nuevamente.",
            color: "danger",
            timeout: 6000,
          };
          self.infoTexts = {
            color: "warning",
            title: "Información",
            text:
              "Selecciona una ruta o activa el tracking para ver en tiempo real la posición del dispositivo.",
          };
          firebase
            .database(self.$store.state.trackingDB)
            .ref(path)
            .off("child_added", listener);
        });
    },
    deactivateTracking() {
      const self = this;
      const cuadrante = this.$store.state.userData.info.cuadrante_id;
      const id_usuario = this.$store.state.userData.id;
      const data = {
        device_id: this.trackingInfo.device_id,
        trackingStatus: 2,
      };
      console.log("data: ", data);
      this.toogleTracking = true;

      const path =
        "SOSclick/tracking/" +
        cuadrante +
        "/" +
        id_usuario +
        "/" +
        this.trackingInfo.device_id;
      firebase
        .database(self.$store.state.trackingDB)
        .ref(path)
        .on("child_removed", function() {
          firebase
            .database(self.$store.state.trackingDB)
            .ref(path)
            .off("child_removed");
          self.infoTexts = {
            color: "warning",
            title: "Información",
            text:
              "Selecciona una ruta o activa el tracking para ver en tiempo real la posición del dispositivo.",
          };
          self.snackbar = {
            status: true,
            text: "El tracking ha sido desactivado en el dispositivo.",
            color: "success",
            timeout: 4000,
          };
          self.toogleTracking = false;
          self.online = false;
        });

      activateTrackingRemote(data)
        .then(function() {
          console.log("Enviado");
        })
        .catch(function(error) {
          console.log(error);
          firebase
            .database(self.$store.state.trackingDB)
            .ref(path)
            .off("child_removed");
          self.toogleTracking = false;
          self.online = false;
          self.snackbar = {
            status: true,
            text:
              "Hubo un error de red al enviar la solicitud, por favor inténtalo nuevamente.",
            color: "danger",
            timeout: 6000,
          };
        });
    },
  },
};
</script>
