import {endpoints} from './endpoints'
import request from '../request'

export function getAtenciones (data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getAtenciones,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}
export function getAtencionDetail (data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getAtencionDetail,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}