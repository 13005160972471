<template>
  <v-container
    fluid
    fill-height
    class="grey lighten-4 py-0"
    style="max-height: 100%;"
  >
    <v-row class="fill-height" style="max-height: 100%;overflow-y: scroll;">
      <v-col
        cols="12"
        sm="5"
        :style="
          $vuetify.breakpoint.mobile ? '' : 'border-right: 1px solid #707070;'
        "
      >
        <v-row>
          <v-col cols="12">
            <h4 class="d-flex align-center">
              <v-img
                :src="require('../../assets/imgs/plans.svg')"
                width="70"
                height="70"
                max-width="70"
                max-height="70"
              ></v-img>
              <span class="headline textcolor-sosclick" style="color: #707070;"
                >Plan Actual</span
              >
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="max-height: 100%;">
            <div v-if="$store.state.userData" style="background-color: white;">
              <Plan
                :plan="$store.state.userData.plan.plan"
                :includes="includes"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="7">
        <v-alert
          color="success"
          dark
          dense
          icon="mdi-information"
          border="left"
          prominent
          class="custom-alert-info mb-0"
          style="max-width: 400px;margin-left: auto;"
        >
          <h5>Actualiza tu Plan</h5>
          <p class="caption mb-0">
            Puedes actualizar tu plan a uno que se adapte mejor a tus
            necesidades.
          </p>
        </v-alert>
        <div
          :key="index"
          v-for="(plan, index) in planes"
          style="background-color: white;"
        >
          <Plan
            :plan="plan"
            :includes="includes"
            class="my-5"
            :bigger="true"
            :actual="
              $store.state.userData ? $store.state.userData.plan.plan : null
            "
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Plan from "./Plan";
import { getPlanes } from "../../helpers/api/user";
export default {
  name: "Planes",
  components: { Plan },
  data: () => ({
    planes: [],
    includes: [],
  }),
  mounted() {
    this.getPlanesinfo();
  },
  methods: {
    toPrice(price) {
      var formatter = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "CLP",
        currencyDisplay: "symbol",
      });
      return formatter.format(price);
    },
    getTextInclude(include) {
      const includes = this.includes.find((item) => {
        return item.id === include;
      });
      return includes.name;
    },
    async getPlanesinfo() {
      const planes = await getPlanes();
      this.planes = planes.planes;
      this.includes = planes.includes;
    },
  },
};
</script>
