var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"custom-header",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center pt-0 pb-0",staticStyle:{"position":"relative"},attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"d-flex align-center justify-center text-center",staticStyle:{"cursor":"pointer"}},[_c('v-img',{attrs:{"src":require('../../assets/imgs/logo-top.svg'),"contain":"","height":"60","width":"60"},on:{"click":function($event){_vm.$router.push({ name: 'Home' }).catch(() => {})}}}),(_vm.$vuetify.breakpoint.mobile)?_c('v-app-bar-nav-icon',{attrs:{"dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('setDrawerState', !_vm.$store.state.drawer)}}}):_vm._e()],1)]),_c('v-col',{class:[
          'Information',
          'Atenciones',
          'Planes',
          'Tracking',
          'Sales',
          'Mediciones',
          'HistoricoMediciones',
          'AutoConfig'
        ].includes(_vm.$route.name)
          ? 'text-right' //' d-flex justify-space-between'
          : 'text-right',staticStyle:{"display":"flex","align-items":"center","flex-wrap":"wrap"},style:(!_vm.$vuetify.breakpoint.xs ? 'justify-content: flex-end' : 'justify-content: space-around'),attrs:{"cols":"12","sm":"9"}},[(
          [
            'Information',
            'Atenciones',
            'Planes',
            'Tracking',
            'Sales',
            'Mediciones',
            'HistoricoMediciones',
            'AutoConfig'
          ].includes(_vm.$route.name)
        )?_c('v-btn',{staticClass:"mx-2 my-2",attrs:{"color":"white","rounded":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")]),_vm._v(" Volver ")],1):_vm._e(),_c('v-btn',{staticClass:"mx-2 my-2",attrs:{"color":"white","rounded":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'Sales' })}}},[_vm._v(" Mi panel de ventas ")]),_c('v-btn',{staticClass:"mx-2 my-2",attrs:{"color":"white","rounded":"","small":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Cerrar Sesión ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }