<template>
  <v-card class="rounded-xl indicador">
    <v-list-item two-line>
      <v-list-item-avatar class="mt-6" tile size="50">
        <v-img :src="require(`../../assets/imgs/${icons[index]}`)"></v-img>
      </v-list-item-avatar>
      <v-list-item-content class="text-center pr-5">
        <v-list-item-title class="text-p mb-6">
          {{ medida }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-h5 bold">
          <strong
            >{{ value[0] }} <small>{{ value[1] }}</small></strong
          >
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "Medicion",
  props: ["medida", "value", "index"],
  data() {
    return {
      icons: ["presion.svg", "presion-1.svg", "presion-2.svg", "ritmo.svg"],
    };
  },
};
</script>

<style></style>
