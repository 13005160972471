import {endpoints} from './endpoints'
import request from '../request'

export function getTrackingByDevice (data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getTrackingByDevice,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function activateTrackingRemote(data){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.activateTracking,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}