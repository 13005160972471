var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"container",staticClass:"py-0 align-start sidebar-container grey--text text--darken-2",style:(_vm.$vuetify.breakpoint.mobile ? 'height: 100vh; overflow: hidden;' : 'height: 100%; overflow: hidden;'),attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0 mt-0"},[_c('div',{staticClass:"d-flex align-center justify-center text-center",staticStyle:{"background":"linear-gradient(180deg, #066fe5 0%, #4baaef 100%) 0%","opacity":"1"}},[_c('v-img',{class:'hidden-lg-and-up',attrs:{"src":require('../../assets/imgs/logo-top.svg'),"contain":"","height":"100","width":"100"}}),_c('v-btn',{class:'hidden-lg-and-up white blue--text font-weight-bold ma-4',attrs:{"icon":"","color":"#707070"},on:{"click":function($event){return _vm.$store.commit('setDrawerState', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{ref:"topContainer",staticClass:"d-flex align-center px-4 py-3"},[_c('v-avatar',{attrs:{"size":"70"}},[(
                _vm.$store.state.userData &&
                  _vm.$store.state.userData.info &&
                  _vm.$store.state.userData.info.avatar
              )?_c('v-img',{attrs:{"src":_vm.$store.state.userData.info.avatar,"width":"70","height":"70"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)}):_c('span',{staticClass:"rounded-circle background-sosclick white--text headline textcolor-sosclick",staticStyle:{"width":"70px","height":"70px","line-height":"70px"}},[_vm._v(" "+_vm._s(_vm.$store.state.userData ? _vm.$store.state.userData.name.match(/\b(\w)/g).join("") : "")+" ")])],1),_c('div',{staticClass:"px-2"},[_c('p',{staticClass:"mb-0 subtitle-2"},[_vm._v(" "+_vm._s(_vm.$store.state.userData ? _vm.$store.state.userData.name : "")+" ")]),_c('a',{staticClass:"pa-0 ma-0 textcolor-sosclick subtitle-2 font-weight-regular",on:{"click":function($event){return _vm.editProfile()}}},[_vm._v("Editar Perfil")])])],1)])],1),_c('v-divider',{staticStyle:{"border-color":"#707070"}}),_c('div',{staticClass:"d-flex flex-column",style:(_vm.$vuetify.breakpoint.mobile ? 'max-height: 35%; overflow-y: auto;' : 'max-height: 40%; overflow-y: auto;')},[_c('p',{staticClass:"mb-0 ml-3 textcolor-sosclick body-4 font-weight-medium py-3"},[_vm._v(" Dispositivos ")]),(
            _vm.$store.state.userData &&
              _vm.$store.state.userData.dispositivos.length
          )?_c('div',{staticStyle:{"overflow-y":"auto"}},_vm._l((_vm.$store.state.userData.dispositivos),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-center pa-0 mx-4 my-2"},[_c('v-icon',{staticClass:"mr-3"},[_vm._v(" mdi-cellphone ")]),_c('div',{staticClass:"px-2"},[_c('p',{staticClass:"mb-0 subtitle-2"},[_vm._v(" "+_vm._s(item.device_name)+" ")]),_c('p',{staticClass:"pa-0 ma-0 subtitle-2 font-weight-regular"},[_vm._v(_vm._s(item.device_model))])])],1)}),0):_c('div',{staticClass:"mb-0 text-center body-2 py-3"},[_vm._v(" No hay dispositivos asociados a tu cuenta ")])]),_c('v-divider',{staticStyle:{"border-color":"#707070"}}),_c('div',{staticClass:"d-flex flex-column",style:(_vm.$vuetify.breakpoint.mobile ? 'max-height: 35%; overflow-y: auto;' : 'max-height: 40%; overflow-y: auto;')},[_c('p',{staticClass:"ml-3 textcolor-sosclick body-4 font-weight-medium my-3"},[_vm._v(" Smartbands ")]),(
            _vm.$store.state.userData &&
              _vm.$store.state.userData.smartbands.length
          )?_c('div',{staticStyle:{"overflow-y":"auto !important","border-top":"1px solid #707070 !important"}},_vm._l((_vm.$store.state.userData.smartbands),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-center pa-0 mx-4 my-2"},[_c('v-icon',{staticClass:"mr-3"},[_vm._v(" mdi-watch-variant ")]),_c('div',{staticClass:"px-2"},[_c('p',{staticClass:"mb-0 subtitle-2"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)}),0):_c('div',{staticClass:"mb-0 text-center body-2 py-3"},[_vm._v(" No hay smartbands asociados a tu cuenta ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }