<template>
	<v-container fill-height align-start>
		<v-row align="start" justify="center" class="text-center">
			<v-col cols="12" sm="6">
				<p class="textcolor-sosclick">Listado de tus referidos</p>
				<v-data-table
					dense
					locale="es-ES"
					:loading="loading"
					loading-text="Cargando Datos"
					:headers="headers"
					:items="referred"
					:item-class="getItemsClass"
					:server-items-length="totalItems"
					@pagination="paginate"
					class="custom-table-atencion"
					:options="tableOptions"
					mobile-breakpoint="0"
				>
					<template v-slot:item.user="{ item }">
						{{ item.user.name }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn color="transparent" tile small depressed
							@click="getUserPayments(item.user)"
						>
							<v-icon color="#707070">{{selectedUserID === item.user.id ? 'mdi-account-off-outline':'mdi-account-outline'}}</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
			<v-col cols="12" sm="6">
				<v-card
					v-if="userInfo"
					class="ma-3 mt-0 pa-10"
				>
					<p class="textcolor-sosclick mb-0">Información usuario</p>
					<p class="body-2" style="color:#707070">Pagos realizados por el usuario seleccionado en el periodo actual</p>
					<div>
						<p class="text-left mb-0 d-flex justify-space-between pa-2">
							<b>Pagos realizados:</b> {{userInfo.data.length}}
						</p>
						<p class="text-left mb-0 d-flex justify-space-between pa-2">
							<b>Monto total pagos:</b> ${{ userInfo.totalAmount | toThousandFilter}}
						</p>
						<p class="body-1 text-left textcolor-sosclick mb-0 d-flex justify-space-between pa-2" style="border-top: 1px solid grey">
							<b>Tu comisión del periodo <span class="caption">(por este usuario)</span>:</b> ${{ Math.round( userInfo.totalAmount * 0.2 ) | toThousandFilter}}
						</p>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import {getStatus, getUserPaymentStatus} from '../../helpers/api/sales'
	export default {
		name: 'SalesUserDetails',
    filters: {
      toThousandFilter(num) {
        return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, '.'))
      }
    },
		data: () => ({
			selectedUserID: null,
			loading: true,
			sales: null,
			referred: [],
			totalItems: 0,			
			headers: [
				{ text: 'Usuario', value: 'user', align: 'center', sortable: false  },
				{ text: 'Visualizar', value: 'actions', align: 'center', sortable: false  }
			],
			tableOptions: {
				page: 1,
				itemsPerPage: 15
			},
			userInfo: null,
		}),
		mounted(){
			this.getSalesStatus(this.tableOptions)
		},
		methods: {
			getItemsClass(item){
				let className = 'custom-items-table'
				if(this.selectedUserID === item.user.id){ className += ' selected-row-custom'}
				return className
			},
			paginate(data){
				this.getSalesStatus(data)
			},
			async getSalesStatus(query){				
				const data = {
					limit: query.itemsPerPage,
					page: query.page
				}
				const sales = await getStatus(data)
				this.referred = sales.referred
				this.totalItems = sales.total_referred
				this.loading = false
			},
			async getUserPayments(user){
				this.selectedUserID = user.id
				const data = {
					user_id: user.id
				}
				const userInfo = await getUserPaymentStatus(data)
				const totalAmount = userInfo.status.reduce(function(acc, item){
						return acc + item.payment.amount
					}, 0)
				this.userInfo = {
					data: userInfo.status,
					totalAmount
				}
			}
		}
	};
</script>