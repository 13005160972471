<template>
  <v-container
    fill-height
    align-start
    style="max-height: 100%; overflow-y: scroll;"
    ref="containerInfo"
  >
    <v-row ref="headerInfo">
      <v-col cols="12" sm="6">
        <h4 class="d-flex align-center">
          <v-img
            :src="require('../../assets/imgs/user.svg')"
            width="70"
            height="70"
            max-width="70"
            max-height="70"
          ></v-img>
          <span class="headline textcolor-sosclick" style="color: #707070;"
            >Histórico de atenciones</span
          >
        </h4>
      </v-col>
      <v-col cols="12" sm="6">
        <v-alert
          color="warning"
          dark
          dense
          icon="mdi-information"
          border="left"
          prominent
          class="custom-alert-info mb-0"
        >
          <h5>Información</h5>
          <p class="caption mb-0">
            Puedes hacer click en la columna "Adjuntos" para visualizar la
            información.
          </p>
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="text-center fill-height" :style="{ height: height + 'px' }">
      <v-col cols="12" sm="6">
        <v-data-table
          dense
          locale="es-ES"
          :loading="loading"
          loading-text="Cargando Datos"
          :headers="headers"
          :items="atenciones"
          :item-class="getItemsClass"
          :server-items-length="totalItems"
          @pagination="paginate"
          class="custom-table-atencion"
          :options="tableOptions"
          mobile-breakpoint="0"
        >
          <template v-slot:item.start="{ item }">
            {{ item.start | date }}
          </template>
          <template v-slot:item.duracion="{ item }">
            {{ getDuration(item.start, item.end) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="
                    item.evidence_count === 0 && item.notes_count === 0
                  "
                  color="transparent"
                  tile
                  small
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  @click="getDetails(item)"
                >
                  <v-icon color="#707070" v-if="item.evidence_count > 0"
                    >mdi-video</v-icon
                  >
                  <v-icon
                    color="#707070"
                    v-if="item.notes_count > 0"
                    style="font-size: 18px;padding-top: 1px;"
                    >mdi-message</v-icon
                  >
                  <v-icon
                    color="#707070"
                    v-if="item.evidence_count === 0 && item.notes_count === 0"
                    style="font-size: 18px;padding-top: 1px;"
                  >
                    mdi-dots-horizontal
                  </v-icon>
                </v-btn>
              </template>
              <span>Ver más información</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" sm="6" style="max-height: 100%;overflow-y: scroll;">
        <div v-if="atencionInfo">
          <v-row
            class="text-center fill-height"
            :style="{ height: height + 'px' }"
          >
            <v-col cols="12" class="pa-0">
              <v-row class="text-center">
                <v-col cols="12" class="py-0">
                  <div class="text-center mb-0"><b>Notas</b></div>
                  <div
                    class="text-center mb-0 caption"
                    v-show="atencionInfo.notes.length == 0"
                  >
                    No hay notas para esta atención
                  </div>
                  <div
                    v-for="(nota, index) in atencionInfo.notes"
                    :key="index"
                    class="mb-2"
                  >
                    <p class="mb-0 font-weight-bold body-2">{{ nota.title }}</p>
                    <p class="mb-0 px-1 body-2">{{ nota.description }}</p>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row>
                <v-col cols="12" class="py-0">
                  <div class="text-center mb-0"><b>Evidencias</b></div>
                  <div
                    class="text-center mb-2 caption"
                    v-show="atencionInfo.evidence.length == 0"
                  >
                    No hay evidencias para esta atención
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="video-evidence-container mb-2"
                  v-for="(evidence, index) in atencionInfo.evidence"
                  :key="index"
                >
                  <video-player
                    class="video-player-box"
                    :class="'rotate' + rotate"
                    ref="videoPlayer"
                    :options="{
                      language: 'es',
                      sources: [
                        {
                          type: 'video/mp4',
                          // src: 'https://zonecam.naturalphone.cl/apiSOSclick/storage/app/public/' + evidence.file_url
                          src:
                            'https://api.sosclick.cl/storage/' +
                            evidence.file_url,
                        },
                      ],
                    }"
                    :playsinline="true"
                  >
                  </video-player>
                  <div>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          tile
                          small
                          depressed
                          dark
                          color="#007BED"
                          class="rotate-button"
                          v-bind="attrs"
                          v-on="on"
                          @click="rotateVideo()"
                        >
                          <v-icon small>
                            mdi-format-rotate-90
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Rotar Video</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- <ModalDetailAtencion :atencionInfo="toModalData" :closeModalAtencion="() => toModalData = null"/> -->
  </v-container>
</template>

<script>
import { getAtenciones, getAtencionDetail } from "../../helpers/api/atenciones";
// import ModalDetailAtencion from './ModalDetailAtencion'
import moment from "moment";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  name: "Atenciones",
  // components: {ModalDetailAtencion},
  components: { videoPlayer },
  filters: {
    date: function(value) {
      if (!value) return "No registra";
      return moment(value).format("DD-MM-YYYY HH:mm");
    },
  },
  data() {
    return {
      toModalData: null,
      atencionInfo: null,
      tableOptions: {
        page: 1,
        itemsPerPage: 15,
      },
      loading: true,
      atenciones: [],
      totalItems: 0,
      headers: [
        {
          class: "custom-header-table-first",
          text: "#",
          value: "id",
          align: "center",
          sortable: false,
        },
        {
          class: "custom-header-table",
          text: "Atencion",
          value: "admin.name",
          align: "center",
          sortable: false,
        },
        {
          class: "custom-header-table",
          text: "Fecha",
          value: "start",
          align: "center",
          sortable: false,
        },
        {
          class: "custom-header-table",
          text: "Duración",
          value: "duracion",
          align: "center",
          sortable: false,
        },
        {
          class: "custom-header-table",
          text: "Adjuntos",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      height: 200,
      rotate: 0,
    };
  },
  mounted() {
    this.height =
      this.$refs.containerInfo.clientHeight -
      this.$refs.headerInfo.clientHeight;
  },
  methods: {
    rotateVideo() {
      this.rotate >= 3 ? (this.rotate = 0) : (this.rotate += 1);
    },
    getDuration(startDate, endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const seconds = end.diff(start, "seconds");
      const result = moment()
        .startOf("day")
        .seconds(seconds)
        .format("HH:mm:ss");
      return result;
    },
    getItemsClass(item) {
      let className = "custom-items-table";
      if (this.atencionInfo && this.atencionInfo.id === item.id) {
        className += " selected-row-custom";
      }
      return className;
    },
    paginate(data) {
      this.getUserAtenciones(data);
    },
    detailAtencion(item) {
      this.toModalData = item;
    },
    async getUserAtenciones(query) {
      this.loading = true;
      const data = {
        limit: query.itemsPerPage,
        page: query.page,
      };
      const atenciones = await getAtenciones(data);
      console.log("atenciones: ", atenciones);
      this.atenciones = atenciones.atenciones;
      this.totalItems = atenciones.total_atenciones;
      this.loading = false;
    },
    async getDetails(atencion) {
      const atencionInfo = await getAtencionDetail({
        atencion_id: atencion.id,
      });
      this.atencionInfo = atencionInfo.atencion;
      const forceRefreshDataTable = [...this.atenciones];
      this.atenciones = forceRefreshDataTable;
    },
  },
};
</script>
