<template>
  <div>
    <ModalNotAllowed
      v-if="$store.state.selectedDevice"
      :dialog="$store.state.modalsStates.notAllowed"
    />
    <ModalSecurityNet :dialog="$store.state.modalsStates.securityNet" />
    <ModalUserProfile :dialog="$store.state.modalsStates.editProfile" />
    <ModalPaymentStatus
      v-if="
        $store.state.modalsStates.paymentStatus.open &&
          $store.state.modalsStates.paymentStatus.data
      "
      :dialog="
        $store.state.modalsStates.paymentStatus.open &&
          $store.state.modalsStates.paymentStatus.data
      "
    />
		<ModalReport v-if="$store.state.reportData" />
  </div>
</template>

<script>
import ModalNotAllowed from "./ModalNotAllowed";
import ModalSecurityNet from "./ModalSecurityNet";
import ModalUserProfile from "./ModalUserProfile";
import ModalPaymentStatus from "./ModalPaymentStatus";
import ModalReport from "./ModalReport";
export default {
  name: "Index",
  components: {
    ModalSecurityNet,
    ModalUserProfile,
    ModalPaymentStatus,
    ModalNotAllowed,
		ModalReport
  },
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
