<template>
	<v-container fluid fill-height class="grey lighten-4 py-0" style="max-height: 100%;" ref="containerInfo">
		<v-row class="fill-height" style="max-height: 100%;">
			<v-col cols="12" sm="12" class="d-flex flex-column px-0">
				<v-row class="flex-grow-0" ref="headerInfo">
					<v-col cols="12">
						<h4 class="d-flex align-center">
							<v-img
								:src="require('../../assets/imgs/plans.svg')"
								width="70"
								height="70"
								max-width="70"
								max-height="70"
							></v-img>
							<span class="headline textcolor-sosclick" style="color: #707070;">Panel de ventas</span>
						</h4>
					</v-col>
				</v-row>
				<v-row align="center" justify="center" class="text-center" style="max-height: 100%;overflow-y: scroll;"
					:style="{'height': height + 'px'}"
					v-if="!sales || !sales.status">
					<v-col cols="12">
						<h3 class="text-center" style="color: #707070;">No haz activado tu cuenta para la sección de ventas</h3>
						<p class="text-center" style="color: #707070;">Puedes hacerlo ahora presionando el siguiente botón</p>
						<v-btn
							color="#007BED"
							depressed
							rounded
							outlined
							dark
							@click="changeSalesStatus(true)"
							class="mx-2 px-5"
						>
							<v-icon class="mr-2">
								mdi-check
							</v-icon>
							<p class="button mb-0">Activar perfil de ventas</p>
						</v-btn>
					</v-col>
				</v-row>
				<v-row align="start" justify="center"
					style="max-height: 100%;overflow-y: scroll;"
					:style="{'height': height + 'px'}"
					v-else>
					<v-tabs
						fixed-tabs
						background-color="#007BED"
						center-active
						dark
					>
						<v-tab class="d-flex align-center">
							<p class="white--text mb-0 ml-5">Resumen</p>
						</v-tab>
						<v-tab class="d-flex align-center">
							<p class="white--text mb-0 ml-5">Detalle de referidos</p>
						</v-tab>
						<v-tab-item>
							<v-card
								flat
							>
								<Resumen />
							</v-card>
						</v-tab-item>
						<v-tab-item>
							<v-card
								flat
							>
								<UserDetails />
							</v-card>
						</v-tab-item>
					</v-tabs>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import {getStatus, changeStatus, getSalesData} from '../../helpers/api/sales'
	import UserDetails from './UserDetails'
	import Resumen from './Resumen'
	export default {
		name: 'Sales',
		components: {UserDetails, Resumen},
		data: () => ({
			selectedUserID: null,
			loading: true,
			sales: null,
			referred: [],
			totalItems: 0,		
			height: 200,	
			headers: [
				{ text: 'Usuario', value: 'user', align: 'center', sortable: false  },
				{ text: 'Visualizar', value: 'actions', align: 'center', sortable: false  }
			],
			tableOptions: {
				page: 1,
				itemsPerPage: 15
			},
		}),
		mounted(){
			this.getSalesStatus(this.tableOptions)
			this.getSalesInfo()
			this.height = this.$refs.containerInfo.clientHeight - this.$refs.headerInfo.clientHeight - 24
		},
		methods: {
			async getSalesInfo(){
				const data = await getSalesData()
				this.$store.commit('setSalesData', data.salesData)
			},
			async getSalesStatus(query){				
				const data = {
					limit: query.itemsPerPage,
					page: query.page
				}
				const sales = await getStatus(data)
				this.sales = sales.status
				this.loading = false
			},
			async changeSalesStatus(status){
				const data = {
					status,
					suspend_reason: 'Petición del usuario'
				}
				await changeStatus(data)
				this.getSalesStatus(this.tableOptions)
			}
		}
	};
</script>