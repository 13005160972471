<template>
  <v-app>
    <v-main>
      <FullContainer />
      <Modals />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import * as firebase from "firebase/app";
import "firebase/auth";
import FullContainer from "./components/Layout/FullContainer";
import { getFBAuthToken } from "./helpers/api/user";
import Modals from "./components/Modals/Index";

export default {
  name: "App",
  components: { FullContainer, Modals },
  computed: {
    ...mapGetters(["userData"]),
  },
  watch: {
    userData: function (data) {
      if (!this.$store.state.primaryDB) {
        this.setFirebaseDB(data);
      }
    },
  },
  data: () => ({}),
  methods: {
    async setFirebaseDB(user) {
      const primaryDB = await this.setPrimaryDB(user);
      this.$store.commit("setPrimaryDB", primaryDB);

      const trackingDB = await this.setTrackingDB(user);
      this.$store.commit('setTrackingDB', trackingDB)

      const monitoreoDB = await this.setMonitoreoDB(user);
      this.$store.commit('setMonitoreoDB', monitoreoDB)
      await this.loginInFB();
    },
    async setPrimaryDB(user) {
      return new Promise((resolve) => {
        const firebaseConfigPrimaryDB = {
          apiKey: "AIzaSyBSglZK8-pgReHnPzQfTcbdz7CyRQCly44",
          authDomain: "iduam-eebce.firebaseapp.com",
          databaseURL: user.info.cuadrante.primary_fdb_url,
          projectId: "iduam-eebce",
          storageBucket: "iduam-eebce.appspot.com",
          messagingSenderId: "574012982731",
          appId: "1:574012982731:web:230b225497bd9b2e8c624e",
          measurementId: "G-JT1JLJW9SP",
        };
        const primaryDB = firebase.initializeApp(
          firebaseConfigPrimaryDB,
          "primaryDB"
        );
        resolve(primaryDB);
      }).catch((error) => {
        console.log(error);
      });
    },
    async setMonitoreoDB(user) {
      return new Promise((resolve) => {
        const firebaseConfigMonitoreo = {
          apiKey: "AIzaSyBSglZK8-pgReHnPzQfTcbdz7CyRQCly44",
          authDomain: "iduam-eebce.firebaseapp.com",
          databaseURL: user.cuadrante.monitoreo_fdb_url,
          projectId: "iduam-eebce",
          storageBucket: "iduam-eebce.appspot.com",
          messagingSenderId: "574012982731",
          appId: "1:574012982731:web:230b225497bd9b2e8c624e",
          measurementId: "G-JT1JLJW9SP",
        };
        const monitoreoDB = firebase.initializeApp(
          firebaseConfigMonitoreo,
          "monitoreoDB"
        );
        resolve(monitoreoDB);
      }).catch((error) => {
        console.log(error);
      });
    },
    async setTrackingDB(user) {
      return new Promise((resolve) => {
        const firebaseConfigTracking = {
          apiKey: "AIzaSyBSglZK8-pgReHnPzQfTcbdz7CyRQCly44",
          authDomain: "iduam-eebce.firebaseapp.com",
          databaseURL: user.cuadrante.tracking_fdb_url,
          projectId: "iduam-eebce",
          storageBucket: "iduam-eebce.appspot.com",
          messagingSenderId: "574012982731",
          appId: "1:574012982731:web:230b225497bd9b2e8c624e",
          measurementId: "G-JT1JLJW9SP",
        };
        const trackingDB = firebase.initializeApp(
          firebaseConfigTracking,
          "trackingDB"
        );
        resolve(trackingDB);
      }).catch((error) => {
        console.log(error);
      });
    },
    async loginInFB() {
      const self = this;
      return new Promise((resolve) => {
        getFBAuthToken()
          .then(async function (response) {
            const token = response.customToken;
            if (token) {
              await self.checkLoginPrimaryDB(token);
            }
            resolve(true);
          })
          .catch(function () {
            resolve(false);
          });
      });
    },
    checkLoginPrimaryDB(token) {
      return new Promise((resolve) => {
        let loggedPrimaryDB = this.$store.state.primaryDB
          .auth()
          .onAuthStateChanged(async (user) => {
            if (!user) {
              await this.$store.state.primaryDB
                .auth()
                .signInWithCustomToken(token);
            }
            loggedPrimaryDB();
            resolve(user);
          });
      });
    },
  },
};
</script>
