<template>
  <!-- <v-toolbar color="#007BED" v-show="this.$store.state.isAuthenticated"> -->
  <v-container fluid class="custom-header" >
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="3"
        class="text-center pt-0 pb-0"
        style="position: relative"
      >
        <div
          style="cursor: pointer"
          class="d-flex align-center justify-center text-center"
        >
          <v-img
            @click="$router.push({ name: 'Home' }).catch(() => {})"
            :src="require('../../assets/imgs/logo-top.svg')"
            contain
            height="60"
            width="60"
          />
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mobile"
            dark
            @click.stop="$store.commit('setDrawerState', !$store.state.drawer)"
          ></v-app-bar-nav-icon>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        :class="
          [
            'Information',
            'Atenciones',
            'Planes',
            'Tracking',
            'Sales',
            'Mediciones',
            'HistoricoMediciones',
            'AutoConfig'
          ].includes($route.name)
            ? 'text-right' //' d-flex justify-space-between'
            : 'text-right'
        "
        style="display: flex; align-items: center; flex-wrap: wrap"
        :style="!$vuetify.breakpoint.xs ? 'justify-content: flex-end' : 'justify-content: space-around'"
      >
        <v-btn
          v-if="
            [
              'Information',
              'Atenciones',
              'Planes',
              'Tracking',
              'Sales',
              'Mediciones',
              'HistoricoMediciones',
              'AutoConfig'
            ].includes($route.name)
          "
          color="white"
          rounded
          small
          @click="$router.push({ name: 'Home' })"
          class="mx-2 my-2"
        >
          <v-icon> mdi-chevron-left </v-icon>
          Volver
        </v-btn>
        <v-btn
          class="mx-2 my-2"
          color="white"
          rounded
          small
          @click="$router.push({ name: 'Sales' })"
        >
          Mi panel de ventas
        </v-btn>
        <v-btn class="mx-2 my-2" color="white" rounded small @click="logout()">
          Cerrar Sesión
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Header",
  data: () => ({}),
  methods: {
    logout() {
      this.$store.commit("logoutUser", null);
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
.custom-header {
  background: transparent linear-gradient(180deg, #066fe5 0%, #4baaef 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  
}
</style>
